import dayjs from "dayjs"
import React from "react"

type YearMonthProps = {
  timestamp?: number
}

const formatYearMonth = (timestamp?: number): string =>
  timestamp ? dayjs(timestamp).format("YYYY / MM") : "-"

export const YearMonth: React.FC<YearMonthProps> = ({
  timestamp,
}: YearMonthProps) => {
  return <>{formatYearMonth(timestamp)}</>
}
