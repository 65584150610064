import {
  CreateKeywordRequest,
  Keyword,
  KeywordBase,
  UpdateKeywordRequest,
} from "../../../../common/model/keywords"
import { dataItemForm } from "./DataItemForm"

export const KeywordForm = dataItemForm<
  Keyword,
  KeywordBase,
  CreateKeywordRequest,
  UpdateKeywordRequest
>({
  itemsKey: "keywords",
  fields: [
    {
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
    },
    {
      name: "categoryId",
      label: "Keyword Category",
      placeholder: "Keyword Category",
      wide: true,
      type: "select-one",
      options: (appState) =>
        (appState["keyword-categories"].data ?? [])
          .map(({ id, name }) => ({
            value: id,
            label: name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
