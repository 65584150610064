import { Typography } from "antd"
import { Client } from "../../../common/model/clients"
import { ClientForm } from "../components/form/ClientForm"
import { UploadForm } from "../components/form/UploadForm"
import { resolveVerticalName } from "../components/render/VerticalName"
import { ClientListItem } from "../model/clients"
import { ClientDetailsView } from "./ClientDetailsView"
import { listingView } from "./ListingView"

export const ClientsView = listingView<Client, ClientListItem>({
  itemsKey: "clients",
  addItemTitle: "New Client",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected clients?",
  addItemForm: <ClientForm />,
  editItemForm: (item) => <ClientForm itemId={item.id} />,
  detailsView: (item) => <ClientDetailsView itemId={item.id} />,
  actions: ["remove"],
  uploadItemsForm: (
    <UploadForm
      itemsKey="clients"
      dependencies={["verticals"]}
      examples={[
        {
          description: (
            <Typography.Text>
              Import clients without the <code>id</code> column. Should be used
              when creating new clients.
            </Typography.Text>
          ),
          rows: [
            "name, vertical",
            "Acme Inc., Cleaning",
            "Disney Corp., Entertainment",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import clients with the <code>id</code> column. Should be used
              when modifying existing clients. Note that the <code>id</code>{" "}
              column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, vertical, id",
            "Acme Inc., Cleaning, 14a3e998-4ba5-4de4-94ad-ee5f8348f199",
            "Disney Corp., Entertainment, 94eff251-de39-4bad-8f2c-05a5a189a988",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "name",
              required: true,
              description: (
                <Typography.Text>
                  Client name. Used to find an existing client to update if the{" "}
                  <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "vertical",
              description:
                "Name of the industry vertical where the client belongs to. " +
                "If no vertical exists with the given name, a new vertical will be created.",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Client id. Used to find an existing vendor to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update name of an
                      existing client.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  columns: [
    {
      property: "name",
      title: "Name",
      width: "400px",
    },
    {
      property: "verticalId",
      title: "Vertical",
      displayValue: (appState, item) =>
        resolveVerticalName(appState, item.verticalId),
      filter: true,
    },
  ],
  toListItem: (item, appState) => {
    const verticalName = resolveVerticalName(appState, item.verticalId)
    return {
      ...item,
      verticalName,
      text: [item.name, verticalName].join(" ").toLowerCase(),
    }
  },
})
