import {
  App,
  Button,
  Col,
  Form,
  FormInstance,
  FormProps,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd"
import type { NamePath } from "rc-field-form/lib/interface"
import React, { useContext, useEffect, useState } from "react"
import { DataItem, Versioned } from "../../../../common/model/common"
import { Api } from "../../api"
import { HttpClientV2 } from "../../api/http-client"
import { ApiContext } from "../../ApiContext"
import { AppState, useAppContext } from "../../context/AppContext"
import { formatLocalTimestamp } from "../../utils"
import { AppDrawerContext } from "../AppDrawer"

interface SubmitButtonProps {
  form: FormInstance
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  form,
  children,
}) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false)

  // Watch all values
  const values = Form.useWatch([], form)

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false))
  }, [form, values])

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  )
}

// TODO: proper typing for different fields
type Field<T> = {
  name: NamePath<T>
  label: string
  placeholder: string
  extra?: string
  type?: "text" | "long-text" | "select-one" | "select-multiple"
  required?: boolean
  wide?: boolean
  focus?: boolean
  options?: (
    appState: AppState,
  ) => Array<{ value: string | boolean | number; label: string }>
}

type CreateItemFormProps<
  DATA_ITEM extends DataItem,
  FORM_ITEM extends object,
  CREATE_REQUEST extends object,
  UPDATE_REQUEST extends Versioned,
> = {
  fields: ReadonlyArray<Field<FORM_ITEM>>
  itemsKey: keyof AppState
  toForm: (item: DATA_ITEM) => FORM_ITEM
  toCreateRequest: (item: FORM_ITEM) => CREATE_REQUEST
  toUpdateRequest: (item: FORM_ITEM, version: number) => UPDATE_REQUEST
}

type ItemFormProps<DATA_ITEM extends DataItem> = {
  itemId?: string
  onCancel?: () => void
  onComplete?: (item: DATA_ITEM) => void
}

export const dataItemForm = <
  DATA_ITEM extends DataItem,
  FORM_ITEM extends object,
  CREATE_REQUEST extends object,
  UPDATE_REQUEST extends Versioned,
>(
  props: CreateItemFormProps<
    DATA_ITEM,
    FORM_ITEM,
    CREATE_REQUEST,
    UPDATE_REQUEST
  >,
): React.FC<ItemFormProps<DATA_ITEM>> => {
  let currentRow = new Array<Field<FORM_ITEM>>()
  const fieldRows = new Array(currentRow)

  props.fields.forEach((field) => {
    if (field.wide) {
      currentRow = [field]
      fieldRows.push(currentRow)
    } else {
      currentRow.push(field)
    }
  })

  // TODO: Add auto focus to the first field
  const DataItemForm: React.FC<ItemFormProps<DATA_ITEM>> = (
    formProps: ItemFormProps<DATA_ITEM>,
  ) => {
    const drawerState = useContext(AppDrawerContext)
    const { appState, dispatchAppState } = useAppContext()
    const { notification } = App.useApp()
    const api = useContext(ApiContext)
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState<FORM_ITEM | undefined>()
    const [existingItem, setExistingItem] = useState<DATA_ITEM | undefined>()
    const [initialLoading, setInitialLoading] = useState(true)
    const [form] = Form.useForm()

    // TODO: Remove casts
    const crud = api[props.itemsKey as keyof Api] as unknown as HttpClientV2<
      DATA_ITEM,
      CREATE_REQUEST,
      UPDATE_REQUEST
    >

    const fetchData = () => {
      if (!formProps.itemId) {
        setInitialLoading(false)
        return
      }

      crud.getById(formProps.itemId).then((item) => {
        setInitialValues(props.toForm(item))
        setExistingItem(item)
        setInitialLoading(false)
      })
    }

    useEffect(() => {
      fetchData()
    }, [])

    useEffect(() => {
      const handleKeyPress = (event: any) => {
        if (event.metaKey === true) {
          if (event.key === "Enter") {
            form.submit()
          }
        }
      }

      // attach the event listener
      document.addEventListener("keydown", handleKeyPress)

      // remove the event listener
      return () => {
        document.removeEventListener("keydown", handleKeyPress)
      }
    })

    const onFinish: FormProps<FORM_ITEM>["onFinish"] = (values: FORM_ITEM) => {
      setLoading(true)

      const operation = () => {
        if (existingItem) {
          const request = props.toUpdateRequest(values, existingItem.version)
          console.log("Update request")
          console.log(JSON.stringify(request, null, 2))

          return crud.update(existingItem.id, request)
        } else {
          const request = props.toCreateRequest(values)
          console.log("Create request")
          console.log(JSON.stringify(request, null, 2))

          return crud.create(request)
        }
      }
      operation()
        .then((data) => {
          dispatchAppState({
            data: [data],
            action: "update-data-items",
            key: props.itemsKey,
          })

          notification.success({
            message: existingItem ? "Update succeeded!" : "Create succeeded!",
          })

          const onComplete =
            formProps.onComplete ?? ((d: DATA_ITEM) => drawerState.close())

          onComplete(data)

          setLoading(false)
        })
        .catch((err) => {
          notification.error({
            message: existingItem ? "Update failed!" : "Create failed!",
          })
          setLoading(false)
        })
    }

    const onFinishFailed: FormProps<FORM_ITEM>["onFinishFailed"] = (
      errorInfo,
    ) => {
      // console.log("Failed:", errorInfo)
    }

    const rows = fieldRows.map((fields, rowIndex) => {
      const cols = fields.map((field, fieldIndex) => {
        const span = field.wide ? 24 : 12

        let input = <Input placeholder={field.placeholder} />

        switch (field.type ?? "text") {
          case "text":
            input = <Input placeholder={field.placeholder} />
            break
          case "long-text":
            input = <Input.TextArea rows={10} placeholder={field.placeholder} />
            break
          case "select-one":
            input = (
              <Select
                options={field.options ? field.options(appState) : []}
                placeholder={field.placeholder}
                optionFilterProp="label"
                allowClear
                showSearch
              />
            )
            break
          case "select-multiple":
            input = (
              <Select
                options={field.options ? field.options(appState) : []}
                placeholder={field.placeholder}
                optionFilterProp="label"
                mode="multiple"
                allowClear
                showSearch
              />
            )
            break
          default:
            throw new Error(`Unknown field type: ${field.type}`)
        }

        return (
          <Col span={span} key={fieldIndex}>
            <Form.Item<FORM_ITEM>
              name={field.name}
              label={field.label}
              extra={field.extra}
              rules={[{ required: field.required }]}
            >
              {input}
            </Form.Item>
          </Col>
        )
      })

      return (
        <Row gutter={16} key={rowIndex}>
          {cols}
        </Row>
      )
    })

    if (initialLoading) {
      return <Skeleton active />
    }

    const onCancel =
      formProps.onCancel ??
      (() => {
        drawerState.close()
      })

    return (
      <Form
        form={form}
        clearOnDestroy
        disabled={loading || initialLoading}
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        name="validateOnly"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        {rows}
        <Row gutter={16}>
          <Col span={25}>
            <Form.Item>
              <Space>
                <SubmitButton form={form}>Save (cmd + Enter)</SubmitButton>
                <Button onClick={onCancel}>Cancel (Esc)</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Typography.Text type="secondary">
              {existingItem
                ? `Last modified by ${
                    existingItem.modifiedBy
                  } at ${formatLocalTimestamp(existingItem.modifiedAt)}`
                : ""}
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    )
  }

  return DataItemForm
}
