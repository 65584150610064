import { Space, Typography } from "antd"
import { Keyword } from "../../../common/model/keywords"
import { KeywordForm } from "../components/form/KeywordForm"
import { resolveKeywordCategoryName } from "../components/render/KeywordCategoryName"
import { detailsView } from "./DetailsView"

export const KeywordDetailsView = detailsView<Keyword>({
  itemsKey: "keywords",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <KeywordForm itemId={item.id} onComplete={onComplete} onCancel={onCancel} />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Category</Typography.Text>
        <Typography.Text>
          {resolveKeywordCategoryName(appState, item.categoryId)}
        </Typography.Text>
      </Space>
    )
  },
})
