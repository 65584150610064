import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type VendorNameProps = {
  id: string
}

export const resolveVendorName = (
  appState: AppState,
  vendorId?: string,
): string => {
  if (!vendorId) {
    return "-"
  }

  const vendor = appState.vendors.data?.find((v) => v.id === vendorId)
  return vendor?.name ?? "-"
}

export const VendorName: React.FC<VendorNameProps> = ({
  id,
}: VendorNameProps) => {
  const { appState } = useAppContext()
  return <>{resolveVendorName(appState, id)}</>
}
