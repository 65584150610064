import { Space, Typography } from "antd"
import { Project } from "../../../common/model/projects"
import { ProjectForm } from "../components/form/ProjectForm"
import { ClientName } from "../components/render/ClientName"
import { resolveKeywordName } from "../components/render/KeywordName"
import { VendorName } from "../components/render/VendorName"
import { YearMonth } from "../components/render/YearMonth"
import { detailsView } from "./DetailsView"

export const ProjectDetailsView = detailsView<Project>({
  itemsKey: "projects",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <ProjectForm itemId={item.id} />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Client</Typography.Text>
        <Typography.Text>
          <ClientName id={item.clientId} />
        </Typography.Text>
        <Typography.Text strong>Vendor</Typography.Text>
        <Typography.Text>
          <VendorName id={item.vendorId} />
        </Typography.Text>
        <Typography.Text strong>Start Month (inclusive)</Typography.Text>
        <Typography.Text>
          <YearMonth timestamp={item.startDate} />
        </Typography.Text>
        <Typography.Text strong>End Month (inclusive)</Typography.Text>
        <Typography.Text>
          <YearMonth timestamp={item.endDate} />
        </Typography.Text>
        <Typography.Text strong>Description</Typography.Text>
        <Typography.Text>{item.description ?? "-"}</Typography.Text>
        <Typography.Text strong>Keywords</Typography.Text>
        <Typography.Text>
          <ul>
            {item.keywordIds?.map((keywordId) => (
              <li>{resolveKeywordName(appState, keywordId)}</li>
            ))}
          </ul>
        </Typography.Text>
      </Space>
    )
  },
})
