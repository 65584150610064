import {
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useState,
} from "react"
import { AppDrawerContext } from "../components/AppDrawer"
import { resolveClientName } from "../components/render/ClientName"
import { resolveEmployeeName } from "../components/render/EmployeeName"
import { resolveVendorName } from "../components/render/VendorName"
import { useAppContext } from "../context/AppContext"
import { ProjectListItem } from "../model/projects"
import { ProjectDetailsView } from "./ProjectDetailsView"

type SearchTerms = {
  vendorIds?: ReadonlyArray<string>
  clientIds?: ReadonlyArray<string>
  verticalIds?: ReadonlyArray<string>
  employeeIds?: ReadonlyArray<string>
  startDate?: Dayjs
  endDate?: Dayjs
  keywords?: Record<string, boolean>
  query?: string
}

type KeywordOptionType = { id: string; name: string; visible: boolean }

type KeywordCategoryOptionType = {
  keywords: ReadonlyArray<KeywordOptionType>
  id?: string
  name: string
  visible: boolean
}

type AggregatedProjectData = ProjectListItem & {
  employeeIds: ReadonlyArray<string>
  verticalId?: string
}

export const SearchView: React.FC = () => {
  const { appState } = useAppContext()
  const [form] = Form.useForm()

  const drawer = useContext(AppDrawerContext)
  const clients = appState.clients.data ?? []
  const vendors = appState.vendors.data ?? []
  const employees = appState.employees.data ?? []
  const verticals = appState.verticals.data ?? []
  const loading = appState.projects.loading

  const [projects, setProjects] = useState<
    ReadonlyArray<AggregatedProjectData>
  >([])

  const [matchingProjects, setMatchingProjects] = useState<
    ReadonlyArray<AggregatedProjectData>
  >([])

  const [keywordCategories, setKeywordCategories] = useState<
    ReadonlyArray<KeywordCategoryOptionType>
  >([])

  const [matchingKeywordCategories, setMatchingKeywordCategories] = useState<
    ReadonlyArray<KeywordCategoryOptionType>
  >([])

  useEffect(() => {
    if (
      !appState.projects.data ||
      !appState.employees.data ||
      !appState.clients.data
    ) {
      return
    }

    const aggregatedProjects = appState.projects.data.map((project) => {
      const employeeIds = _.uniq(
        (project.assignments ?? []).map((assignment) => assignment.employeeId),
      )

      const employeeNames = employeeIds
        .map((employeeId) => resolveEmployeeName(appState, employeeId))
        .sort((a, b) => a.localeCompare(b))

      const clientName = resolveClientName(appState, project.clientId)
      const client = (appState.clients.data ?? []).find(
        (client) => client.id === project.clientId,
      )

      const vendorName = resolveVendorName(appState, project.vendorId)

      return {
        ...project,
        employeeIds,
        verticalId: client?.verticalId,
        employeeNames,
        clientName,
        vendorName,
        text: [project.name, clientName, vendorName].join(" ").toLowerCase(),
      }
    })

    setProjects(aggregatedProjects)
    setMatchingProjects(aggregatedProjects)
  }, [appState])

  useEffect(() => {
    const unknownKeywordCategory = {
      id: undefined,
      name: "[Unspecified]",
    }

    const keywords = appState.keywords.data ?? []

    const categories: ReadonlyArray<KeywordCategoryOptionType> = [
      ...(appState["keyword-categories"].data ?? []),
      unknownKeywordCategory,
    ]
      .map((keywordCategory) => {
        return {
          ..._.pick(keywordCategory, "id", "name"),
          visible: true,
          keywords: keywords
            .filter((keyword) => {
              return keywordCategory.id
                ? keyword.categoryId === keywordCategory.id
                : !keyword.categoryId
            })
            .map((keyword) => ({
              ..._.pick(keyword, "id", "name"),
              visible: true,
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        }
      })
      .filter((keywordCategory) => keywordCategory.keywords.length > 0)
      .sort((a, b) => a.name.localeCompare(b.name))

    setKeywordCategories(categories)
    setMatchingKeywordCategories(categories)
  }, [appState])

  const clientsOptions = clients
    .map((client) => ({
      label: client.name,
      value: client.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const vendorOptions = vendors
    .map((vendor) => ({
      label: vendor.name,
      value: vendor.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const verticalOptions = verticals
    .map((vertical) => ({
      label: vertical.name,
      value: vertical.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const employeesOptions = employees
    .map((employee) => ({
      label: employee.lastName + " " + employee.firstName,
      value: employee.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const columns: ColumnsType<AggregatedProjectData> = [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      width: "400px",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Client",
      dataIndex: "clientName",
      key: "clientName",
      width: "300px",
      sorter: (a, b) => {
        const a1 = a.clientName ?? ""
        const b1 = b.clientName ?? ""
        return a1.localeCompare(b1)
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text: number) => (text ? dayjs(text).format("YYYY / MM") : "-"),
      width: "150px",
      sorter: (a, b) => {
        if (!a.startDate && !b.startDate) {
          return 0
        }

        if (a.startDate && !b.startDate) {
          return 1
        }

        if (!a.startDate && b.startDate) {
          return -1
        }

        return a.startDate! > b.startDate! ? 1 : -1
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: number) => (text ? dayjs(text).format("YYYY / MM") : "-"),
      width: "150px",
      sorter: (a, b) => {
        if (!a.endDate && !b.endDate) {
          return 0
        }

        if (a.endDate && !b.endDate) {
          return 1
        }

        if (!a.endDate && b.endDate) {
          return -1
        }

        return a.endDate! > b.endDate! ? 1 : -1
      },
    },
    {
      title: "Employees",
      dataIndex: "employeeNames",
      key: "employeeNames",
      render: (text: string, item) => item.employeeNames?.join(", "),
      width: "300px",
    },
  ]

  const onSearchTermsChange = (
    changedValues: any,
    searchTerms: SearchTerms,
  ) => {
    console.log(
      `onValuesChange: ${JSON.stringify(changedValues, undefined, 2)}`,
    )

    let matching = projects

    if (searchTerms.startDate) {
      const startDateTimestamp = searchTerms.startDate.valueOf()

      matching = matching.filter((p) => {
        return !(p.startDate && p.startDate < startDateTimestamp)
      })
    }

    if (searchTerms.endDate) {
      const endDateTimestamp = searchTerms.endDate.valueOf()

      matching = matching.filter((p) => {
        return !(p.endDate && p.endDate > endDateTimestamp)
      })
    }

    if (searchTerms.clientIds && searchTerms.clientIds.length > 0) {
      matching = matching.filter((p) =>
        searchTerms.clientIds?.includes(p.clientId),
      )
    }

    if (searchTerms.verticalIds && searchTerms.verticalIds.length > 0) {
      matching = matching.filter(
        (p) => p.verticalId && searchTerms.verticalIds?.includes(p.verticalId),
      )
    }

    if (searchTerms.employeeIds && searchTerms.employeeIds.length > 0) {
      matching = matching.filter((p) =>
        searchTerms.employeeIds?.some((employeeId) =>
          p.employeeIds.includes(employeeId),
        ),
      )
    }

    if (searchTerms.keywords && Object.keys(searchTerms.keywords).length > 0) {
      const selectedKeywords = Object.entries(searchTerms.keywords)
        .filter(([keywordId, value]) => value)
        .map(([keywordId, value]) => keywordId)

      matching = matching.filter((p) =>
        selectedKeywords.every((keywordId) =>
          p.keywordIds?.includes(keywordId),
        ),
      )
    }

    if (searchTerms.query && searchTerms.query.trim().length > 0) {
      const cleanedQuery = searchTerms.query.trim().toLowerCase()
      matching = matching.filter((p) => p.text.includes(cleanedQuery))
    }

    setMatchingProjects(matching)
  }

  const projectKeywordQueryChanged: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const query = target.value.trim().toLowerCase()

    console.log(query)

    const updatedMatchingKeywordCategories = keywordCategories
      .map((category) => {
        const keywords = category.keywords.map((keyword) => ({
          ...keyword,
          visible: keyword.name.toLowerCase().includes(query),
        }))

        return {
          ...category,
          visible: keywords.some((keyword) => keyword.visible),
          keywords,
        }
      })
      .filter((category) => category.keywords.length > 0)

    setMatchingKeywordCategories(updatedMatchingKeywordCategories)
  }

  const openViewDetailsDrawer = (item: AggregatedProjectData) => {
    drawer.open({
      title: "Details",
      content: <ProjectDetailsView itemId={item.id} />,
      open: true,
      loading: false,
      size: "default",
    })
  }

  if (loading) {
    return <Spin delay={200} />
  }

  if (projects.length === 0) {
    return <Empty style={{ marginTop: "40px" }} />
  }

  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Form<SearchTerms>
            form={form}
            clearOnDestroy
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            name="searhForm"
            onValuesChange={onSearchTermsChange}
          >
            <Form.Item<SearchTerms> label="Free Text Search" name="query">
              <Input placeholder="Free Text Search" />
            </Form.Item>
            <Form.Item<SearchTerms> label="Vendors" name="vendorIds">
              <Select
                options={vendorOptions}
                mode="multiple"
                placeholder="Vendors"
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
            <Form.Item<SearchTerms> label="Clients" name="clientIds">
              <Select
                options={clientsOptions}
                mode="multiple"
                placeholder="Clients"
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
            <Form.Item<SearchTerms> label="Verticals" name="verticalIds">
              <Select
                options={verticalOptions}
                mode="multiple"
                placeholder="Verticals"
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
            <Form.Item<SearchTerms> label="Employees" name="employeeIds">
              <Select
                options={employeesOptions}
                mode="multiple"
                placeholder="Employees"
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
            <Form.Item<SearchTerms> label="Start Date After" name="startDate">
              <DatePicker
                picker="month"
                placeholder="Start Date After"
                format="YYYY / MM"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item<SearchTerms> label="End Date Before" name="endDate">
              <DatePicker
                picker="month"
                placeholder="End Date Before"
                format="YYYY / MM"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Typography.Text>Keywords</Typography.Text>
            <Input
              style={{ marginTop: "8px" }}
              placeholder="Type keyword name to limit the number of visible keywords"
              onChange={projectKeywordQueryChanged}
            />
            {matchingKeywordCategories.map((category) => {
              return (
                <div
                  key={String(category.id)}
                  style={{
                    display: category.visible ? "inherit" : "none",
                    marginTop: "8px",
                  }}
                >
                  <Typography.Text strong>{category.name}</Typography.Text>
                  <Row>
                    {category.keywords.map((keyword) => (
                      <Col
                        span={8}
                        key={keyword.id}
                        style={{
                          display: keyword.visible ? "inherit" : "none",
                        }}
                      >
                        <Form.Item
                          name={["keywords", keyword.id]}
                          valuePropName="checked"
                          style={{ marginBottom: 0 }}
                        >
                          <Checkbox style={{ width: "100%" }}>
                            {keyword.name}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </div>
              )
            })}
          </Form>
        </Col>
        <Col span={16}>
          <Table
            dataSource={matchingProjects}
            rowClassName="clickable"
            columns={columns}
            rowKey={(record) => record.id}
            loading={loading}
            scroll={{
              // y: "max-content",
              x: "max-content",
              scrollToFirstRowOnChange: true,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  openViewDetailsDrawer(record)
                },
              }
            }}
            pagination={{
              defaultPageSize: 50,
              position: ["topRight", "bottomRight"],
              total: matchingProjects.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} projects`,
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
