import { Space, Typography } from "antd"
import { KeywordCategory } from "../../../common/model/keyword-categories"
import { KeywordCategoryForm } from "../components/form/KeywordCategoryForm"
import { detailsView } from "./DetailsView"

export const KeywordCategoryDetailsView = detailsView<KeywordCategory>({
  itemsKey: "keyword-categories",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <KeywordCategoryForm
      itemId={item.id}
      onCancel={onCancel}
      onComplete={onComplete}
    />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Description</Typography.Text>
        <Typography.Text>{item.description ?? "-"}</Typography.Text>
      </Space>
    )
  },
})
