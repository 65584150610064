export type AppConfig = {
  Auth: Record<string, unknown>
  uploadBasePath: string
}

const prodConfig = (isLocal: boolean): AppConfig => ({
  Auth: {
    region: "eu-north-1",
    identityPoolId: "eu-north-1:b80b5959-1b84-400d-8e34-9ff307d8672d",
    userPoolId: "eu-north-1_QKGCEQjv4",
    userPoolWebClientId: "7h63gmckgith7267s1apqev5m1",
    mandatorySignIn: true,
    oauth: {
      domain: "quest-prod.auth.eu-north-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: isLocal
        ? "http://localhost:3000/"
        : "https://quest.webscale.fi/",
      redirectSignOut: isLocal
        ? "http://localhost:3000/"
        : "https://quest.webscale.fi/",
      clientId: "7h63gmckgith7267s1apqev5m1",
      responseType: "code",
    },
  },
  uploadBasePath: isLocal
    ? ""
    : "https://r0z10pyfhb.execute-api.eu-north-1.amazonaws.com",
})

const devConfig = (isLocal: boolean): AppConfig => ({
  Auth: {
    region: "eu-north-1",
    identityPoolId: "eu-north-1:e2ccbd45-d4f8-4066-97f7-20dd46e4983c",
    userPoolId: "eu-north-1_ls0THDIuT",
    userPoolWebClientId: "3qqvavdg3ck8kqochecu0rfod",
    mandatorySignIn: true,
    oauth: {
      domain: "quest-dev.auth.eu-north-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: isLocal
        ? "http://localhost:3000/"
        : "https://quest-dev.webscale.fi/",
      redirectSignOut: isLocal
        ? "http://localhost:3000/"
        : "https://quest-dev.webscale.fi/",
      clientId: "3qqvavdg3ck8kqochecu0rfod",
      responseType: "code",
    },
  },
  uploadBasePath: isLocal
    ? ""
    : "https://f14oslz3x3.execute-api.eu-north-1.amazonaws.com",
})

export const getConfig = (hostname: string): AppConfig => {
  switch (hostname) {
    case "localhost":
      return process.env.REACT_APP_ENV === "prod"
        ? prodConfig(true)
        : devConfig(true)
    case "quest-dev.webscale.fi":
      return devConfig(false)
    case "quest.webscale.fi":
      return prodConfig(false)
    default:
      throw new Error(`Unsupported hostname: ${hostname}`)
  }
}
