import {
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import { App, Button, Form, Input, Select, Space, Spin, Table } from "antd"
import { AnyObject } from "antd/es/_util/type"
import { SelectProps } from "rc-select/lib/Select"
import React, { useContext, useEffect, useState } from "react"
import { DataItem } from "../../../common/model/common"
import { PermissionScope } from "../../../common/model/permissions"
import { Api } from "../api"
import { HttpClientV2 } from "../api/http-client"
import { ApiContext } from "../ApiContext"
import { AppDrawerContext } from "../components/AppDrawer"
import { Authorize } from "../components/render/Authorize"
import { AppState, State, useAppContext } from "../context/AppContext"
import { useUserSessionContext } from "../context/UserSessionContext"
import { ListItem } from "../model/common"
import { currentLocalTime } from "../utils"

const downloadFile = (blob: any, fileName: string): void => {
  const href = URL.createObjectURL(blob)
  const a = Object.assign(document.createElement("a"), {
    href,
    style: "display:none",
    download: fileName,
  })
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(href)
  a.remove()
}

type SearchTerms = {
  query?: string
}

export type OperationResult<T> = {
  item: T
  status: "fulfilled" | "rejected"
  reason?: string
}

type ListingViewColumn<LIST_ITEM extends DataItem> = {
  property: keyof LIST_ITEM
  title: string
  filter?: boolean
  displayValue?: (appState: AppState, item: LIST_ITEM) => string
  width?: string
}

type ListingViewProps<
  DATA_ITEM extends DataItem,
  LIST_ITEM extends ListItem,
> = {
  columns: ReadonlyArray<ListingViewColumn<LIST_ITEM>>
  addItemTitle: string
  confirmRemoveItemsQuestion: string
  addItemForm: React.ReactNode
  editItemForm: (item: LIST_ITEM) => React.ReactNode
  detailsView?: (item: LIST_ITEM) => React.ReactNode
  uploadItemsForm?: React.ReactNode
  itemsKey: keyof AppState
  actions?: ReadonlyArray<ActionOptionType>
  toListItem: (item: DATA_ITEM, appState: AppState) => LIST_ITEM
}

type ActionSelectProps = {
  options: SelectProps["options"]
  disabled: boolean
  onChange: (value: ActionOptionType) => void
}

const ActionSelect = ({ disabled, options, onChange }: ActionSelectProps) => (
  <Select
    options={options}
    style={{ width: "200px" }}
    disabled={disabled}
    placeholder="Choose Action"
    onChange={onChange}
  />
)

type ActionOptionType = "remove" | "edit" | "merge"

type ActionOption = {
  value: ActionOptionType
  label: string
  selection: "single" | "multiple"
  scope: PermissionScope
}

export const listingView = <T extends DataItem, LIST_ITEM extends ListItem>(
  props: ListingViewProps<T, LIST_ITEM>,
): React.FC => {
  const View: React.FC = () => {
    const [form] = Form.useForm()
    const { modal, notification } = App.useApp()
    const api = useContext(ApiContext)
    const drawer = useContext(AppDrawerContext)
    const { dispatchAppState, appState } = useAppContext()
    const { userSession } = useUserSessionContext()

    const state = appState[props.itemsKey] as unknown as State<T>
    const [allItems, setAllItems] = useState<ReadonlyArray<LIST_ITEM>>([])
    const [matchingItems, setMatchingItems] = useState<
      ReadonlyArray<LIST_ITEM>
    >([])

    const defaultActionOptions: ReadonlyArray<ActionOption> = [
      {
        value: "remove",
        label: "Remove",
        selection: "multiple",
        scope: `quest.${props.itemsKey}.remove`,
      },
      {
        value: "merge",
        label: "Merge",
        selection: "multiple",
        scope: `quest.${props.itemsKey}.write`,
      },
    ]

    const [selectedItems, setSelectedItems] = useState<
      ReadonlyArray<AnyObject>
    >([])

    const [actionOptions, setActionOptions] = useState<
      Array<{
        label: string
        value: string
        disabled: boolean
      }>
    >([])

    // TODO: Remove casts
    const crud = api[props.itemsKey as keyof Api] as unknown as HttpClientV2<
      T,
      any,
      any
    >

    useEffect(() => {
      const listItems = (state.data ?? []).map((item) =>
        props.toListItem(item, appState),
      )

      setAllItems(listItems)
      setMatchingItems(listItems)
    }, [state, appState])

    useEffect(() => {
      const handleKeyPress = (event: any) => {
        if (event.metaKey === true) {
          if (event.key === "u") {
            openDrawer()
          }
        }
      }

      // attach the event listener
      document.addEventListener("keydown", handleKeyPress)

      // remove the event listener
      return () => {
        document.removeEventListener("keydown", handleKeyPress)
      }
    })

    const openDrawer = () => {
      drawer.open({
        title: props.addItemTitle,
        content: props.addItemForm,
        open: true,
        loading: false,
        size: "default",
      })
    }

    const openImportDrawer = () => {
      drawer.open({
        title: "Import",
        content: props.uploadItemsForm,
        open: true,
        loading: false,
        size: "full",
      })
    }

    const openViewDetailsDrawer = (item: LIST_ITEM) => {
      drawer.open({
        title: "Details",
        content: props.detailsView!(item),
        open: true,
        loading: false,
        size: "default",
        onClose: () => {
          setSelectedItems([])
        },
      })
    }

    const columns = props.columns.map((column, index) => {
      const sorter = (a: any, b: any) => {
        if (column.displayValue) {
          const av = column.displayValue(appState, a)
          const bv = column.displayValue(appState, b)
          return av.localeCompare(bv)
        }

        const av = a[column.property]
        const bv = b[column.property]

        if (typeof av === "number" && typeof bv === "number") {
          return av > bv ? 1 : -1
        }

        return String(av).localeCompare(String(bv))
      }

      return {
        key: String(column.property),
        dataIndex: String(column.property),
        title: column.title,
        width: column.width,
        sorter,
        render: column.displayValue
          ? (text: any, item: LIST_ITEM) => column.displayValue!(appState, item)
          : undefined,
      }
    })

    const rowSelection = {
      type: "checkbox",
      selectedRowKeys: selectedItems.map((item) => item.id),
      onChange: (selectedRowKeys: React.Key[], selectedRows: AnyObject[]) => {
        setSelectedItems(selectedRows)

        const updatedActionOptions = defaultActionOptions
          .filter((option) => userSession.scopes.has(option.scope))
          .filter((option) => props.actions?.includes(option.value))
          .map((option) => {
            const disabled =
              (option.selection === "single" && selectedRows.length !== 1) ||
              (option.selection === "multiple" && selectedRows.length === 0)

            return {
              label: option.label,
              value: option.value,
              disabled,
            }
          })

        setActionOptions(updatedActionOptions)

        if (selectedRows.length === 0) {
          resetActionSelect()
        }
      },
    }

    const resetActionSelect = () => {
      form.resetFields(["action"])
    }

    const onActionChange = (value: ActionOptionType) => {
      if (value === "remove") {
        modal
          .confirm({
            title: "Confirm Deletion",
            content: props.confirmRemoveItemsQuestion,
          })
          .then(
            (confirmed) => {
              if (!confirmed) {
                resetActionSelect()
                return
              }

              return Promise.allSettled(
                selectedItems.map((item) => crud.deleteById(item.id)),
              )
                .then((results) => {
                  return results.reduce((acc, result, index) => {
                    return [
                      ...acc,
                      {
                        item: selectedItems[index],
                        status: result.status,
                        reason:
                          result.status === "rejected"
                            ? result.reason
                            : undefined,
                      },
                    ]
                  }, new Array<OperationResult<AnyObject>>())
                })
                .then((results) => {
                  const rejected = results.filter(
                    (result) => result.status === "rejected",
                  )

                  resetActionSelect()
                  setSelectedItems([])

                  if (rejected.length === 0) {
                    notification.success({
                      message: "Remove succeeded!",
                    })
                  } else {
                    notification.error({ message: "Remove failed!" })
                    // TODO: Implement handling of dependant items
                  }

                  dispatchAppState({
                    action: "remove-data-items",
                    key: props.itemsKey,
                    ids: results.map((r) => r.item.id),
                  })
                })
            },
            () => {
              resetActionSelect()
            },
          )
      }
    }

    const uploadButton = (
      <Authorize scope={`quest.${props.itemsKey}.import`}>
        <Form.Item name="import-button" style={{ marginBottom: "8px" }}>
          <Button icon={<UploadOutlined />} onClick={() => openImportDrawer()}>
            Import
          </Button>
        </Form.Item>
      </Authorize>
    )

    const exportButton = (
      <Authorize scope={`quest.${props.itemsKey}.export`}>
        <Form.Item name="import-button" style={{ marginBottom: "8px" }}>
          <Button
            icon={<DownloadOutlined />}
            onClick={() =>
              crud
                .export()
                .then((blob) =>
                  downloadFile(
                    blob,
                    `${props.itemsKey}-${currentLocalTime().format(
                      "YYYY-MM-DDTHH-mm",
                    )}.csv`,
                  ),
                )
            }
          >
            Export
          </Button>
        </Form.Item>
      </Authorize>
    )

    if (state.loading) {
      return <Spin delay={500} />
    }

    const table =
      allItems.length > 0 ? (
        <Table
          rowClassName="clickable"
          dataSource={matchingItems}
          columns={columns}
          rowKey={(record) => record.id}
          loading={state.loading}
          rowSelection={{ ...rowSelection, type: "checkbox" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                openViewDetailsDrawer(record)
              },
            }
          }}
          scroll={{
            // y: "max-content",
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            defaultPageSize: 50,
            position: ["topRight", "bottomRight"],
            total: matchingItems.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} ${props.itemsKey.replace(
                /-/g,
                " ",
              )}`,
          }}
        />
      ) : undefined

    const onSearchTermsChange = (terms: SearchTerms) => {
      const cleanedTerms: SearchTerms = {
        query: terms.query?.toLowerCase().trim(),
      }

      if (cleanedTerms.query && cleanedTerms.query.length > 0) {
        setMatchingItems(
          allItems.filter((item) => item.text.includes(cleanedTerms.query!)),
        )
      } else {
        setMatchingItems(allItems)
      }
    }

    const searchControls =
      allItems.length > 0 ? (
        <Form<SearchTerms>
          layout="vertical"
          onValuesChange={onSearchTermsChange}
          autoComplete="off"
        >
          <Form.Item<SearchTerms> name="query" style={{ marginBottom: 0 }}>
            <Input placeholder="Search with free text"></Input>
          </Form.Item>
        </Form>
      ) : undefined

    return (
      <div>
        <Form form={form} lang="horizontal" autoComplete="off">
          <Space>
            <Authorize scope={`quest.${props.itemsKey}.write`}>
              <Form.Item name="button" style={{ marginBottom: "8px" }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => openDrawer()}
                >
                  {props.addItemTitle + " (cmd + u)"}
                </Button>
              </Form.Item>
            </Authorize>
            {uploadButton}
            {exportButton}
            <Form.Item name="action" style={{ marginBottom: "8px" }}>
              <ActionSelect
                options={actionOptions}
                disabled={selectedItems.length === 0}
                onChange={onActionChange}
              />
            </Form.Item>
          </Space>
        </Form>
        {searchControls}
        {table}
      </div>
    )
  }

  return View
}
