import { Typography } from "antd"
import { Employee } from "../../../common/model/employees"
import { EmployeeForm } from "../components/form/EmployeeForm"
import { UploadForm } from "../components/form/UploadForm"
import { resolveVendorName } from "../components/render/VendorName"
import { EmployeeListItem } from "../model/employees"
import { EmployeeDetailsView } from "./EmployeeDetailsView"
import { listingView } from "./ListingView"

export const EmployeesView = listingView<Employee, EmployeeListItem>({
  itemsKey: "employees",
  addItemTitle: "New Employee",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected employees?",
  addItemForm: <EmployeeForm />,
  editItemForm: (item) => <EmployeeForm itemId={item.id} />,
  detailsView: (item) => <EmployeeDetailsView itemId={item.id} />,
  actions: ["remove"],
  uploadItemsForm: (
    <UploadForm
      itemsKey="employees"
      examples={[
        {
          description: (
            <Typography.Text>
              Import employees without the <code>id</code> column. Should be
              used when creating new employees.
            </Typography.Text>
          ),
          rows: [
            "email, firstName, lastName, current, vendor",
            "john.doe@company.com, John, Doe, admin",
            "albert.einstein@company.com, Albert, Einstein, sales",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import employees with the <code>id</code> column. Should be used
              when modifying existing employees. Note that the <code>id</code>{" "}
              column can be empty.
            </Typography.Text>
          ),
          rows: [
            "email, firstName, lastName, current, vendor, id",
            "john.doe@company.com, John, Doe, true, Webscale Oy, caa65962-1950-4e9d-b1c4-ffc52fae0643",
            "albert.einstein@company.com, Albert, Einstein, false, Webscale Oy, b27e5dec-6ddb-4b92-85e7-8549cce9c4a0",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "email",
              required: true,
              description: (
                <Typography.Text>
                  Employee email address. Used to find an existing employee to
                  update if the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "firstName",
              required: true,
              description: "Employee first name.",
            },
            {
              name: "lastName",
              required: true,
              description: "Employee last name.",
            },
            {
              name: "current",
              required: true,
              description: (
                <Typography.Text>
                  Is the employee currently working for the vendor? Allowed
                  values are:
                  <ul>
                    <li>
                      true = Employee is currently working for the vendor.
                    </li>
                    <li>
                      false = Employee is not currently working for the vendor.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "vendor",
              description:
                "Name of the vendor for whom the employee works for. If no vendor exists with the given name, a new vendor will be created.",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Employee id. Used to find an existing employee to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update email
                      address of an existing employee.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  columns: [
    {
      property: "email",
      title: "Email",
      width: "400px",
    },
    {
      property: "firstName",
      title: "First Name",
      width: "300px",
    },
    {
      property: "lastName",
      title: "Last Name",
      width: "300px",
    },
    {
      property: "current",
      title: "Current",
      displayValue: (appState, item) => (item.current ? "Yes" : "No"),
      filter: true,
      width: "150px",
    },
    {
      property: "vendorId",
      title: "Vendor",
      displayValue: (appState, item) =>
        resolveVendorName(appState, item.vendorId),
      filter: true,
    },
  ],
  toListItem: (item, appState) => {
    const vendorName = resolveVendorName(appState, item.vendorId)
    return {
      ...item,
      vendorName,
      text: [item.email, item.lastName, item.firstName, vendorName].join(" "),
    }
  },
})
