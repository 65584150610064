import { Button, Divider, Skeleton, Space } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { DataItem } from "../../../common/model/common"
import { Api } from "../api"
import { HttpClientV2 } from "../api/http-client"
import { ApiContext } from "../ApiContext"
import { AppDrawerContext } from "../components/AppDrawer"
import { AppState, useAppContext } from "../context/AppContext"

type EditItemFormProps<DATA_ITEM> = {
  item: DATA_ITEM
  onCancel: () => void
  onComplete: (item: DATA_ITEM) => void
}

type CreateDetailsViewProps<DATA_ITEM extends DataItem> = {
  itemsKey: keyof AppState
  view: (item: DATA_ITEM, appState: AppState) => React.ReactNode
  editItemForm: (props: EditItemFormProps<DATA_ITEM>) => React.ReactNode
}

type DetailsViewProps = {
  itemId: string
}

export const detailsView = <DATA_ITEM extends DataItem>(
  props: CreateDetailsViewProps<DATA_ITEM>,
): React.FC<DetailsViewProps> => {
  const View: React.FC<DetailsViewProps> = (viewProps: DetailsViewProps) => {
    const drawerState = useContext(AppDrawerContext)
    const api = useContext(ApiContext)
    const { appState } = useAppContext()
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState<DATA_ITEM | undefined>(undefined)

    const crud = api[props.itemsKey as keyof Api] as unknown as HttpClientV2<
      DATA_ITEM,
      any,
      any
    >

    const fetchData = () => {
      crud.getById(viewProps.itemId).then((item) => {
        setLoading(false)
        setItem(item)
      })
    }

    useEffect(() => {
      fetchData()
    }, [])

    if (loading) {
      return <Skeleton active />
    }

    if (!item) {
      return <div>Error</div>
    }

    if (editing) {
      return (
        <>
          {props.editItemForm({
            item,
            onCancel: () => setEditing(false),
            onComplete: (item) => {
              setItem(item)
              setEditing(false)
            },
          })}
        </>
      )
    }

    return (
      <>
        {props.view(item, appState)}
        <Divider />
        <div>
          <Space>
            <Button onClick={() => setEditing(true)}>Edit (cmd + e)</Button>
            <Button onClick={() => drawerState.close()}>Close (Esc)</Button>
          </Space>
        </div>
      </>
    )
  }

  return View
}
