import { Drawer } from "antd"
import React, { createContext, useContext } from "react"

export type AppDrawerProps = {
  title: string
  content: React.ReactNode
  open: boolean
  loading: boolean
  onClose?: () => void
  size: "default" | "full"
}

export type CloseAppDrawerProps = {
  onClose?: () => void
}

export type AppDrawerState = {
  close: (props?: CloseAppDrawerProps) => void
  open: (props: AppDrawerProps) => void
  setLoading: (loading: boolean) => void
  props: AppDrawerProps
}

export const AppDrawerContext = createContext<AppDrawerState>({
  close: () => {},
  open: (props: AppDrawerProps) => {},
  setLoading: (loading: boolean) => {},
  props: {
    open: false,
    loading: false,
    title: "",
    content: <div />,
    size: "default",
  },
})

export const AppDrawer: React.FC = () => {
  const drawerState = useContext(AppDrawerContext)

  const onClose = () => {
    drawerState.close()
  }

  return (
    <Drawer
      title={drawerState.props.title}
      width={drawerState.props.size === "full" ? "100%" : 720}
      onClose={onClose}
      open={drawerState.props.open}
      // afterOpenChange={(open) => {}}
      loading={drawerState.props.loading}
      destroyOnClose
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      // extra={
      //   <Space>
      //     <Button onClick={onClose}>Peruuta</Button>
      //     <Button onClick={onClose} type="primary">
      //       Tallenna
      //     </Button>
      //   </Space>
      // }
    >
      {drawerState.props.content}
    </Drawer>
  )
}
