import {
  Contact,
  ContactBase,
  CreateContactRequest,
  UpdateContactRequest,
} from "../../../../common/model/contacts"
import { dataItemForm } from "./DataItemForm"

export const ContactForm = dataItemForm<
  Contact,
  ContactBase,
  CreateContactRequest,
  UpdateContactRequest
>({
  itemsKey: "contacts",
  fields: [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      required: true,
      focus: true,
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      required: true,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Email",
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      placeholder: "Phone",
      required: false,
    },
    {
      name: "clientId",
      label: "Client",
      placeholder: "client",
      type: "select-one",
      options: (appState) =>
        (appState.clients.data ?? []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
