import { CloseOutlined } from "@ant-design/icons"
import {
  Alert,
  App,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  FormProps,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd"
import _ from "lodash"
import type { NamePath } from "rc-field-form/lib/interface"
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useState,
} from "react"
import { DataItem, Versioned } from "../../../../common/model/common"
import { Project, ProjectRole } from "../../../../common/model/projects"
import { Api } from "../../api"
import { HttpClientV2 } from "../../api/http-client"
import { ApiContext } from "../../ApiContext"
import { AppState, useAppContext } from "../../context/AppContext"
import { ProjectFormData } from "../../model/projects"
import { formatLocalTimestamp, id } from "../../utils"
import { AppDrawerContext } from "../AppDrawer"

interface SubmitButtonProps {
  form: FormInstance
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  form,
  children,
}) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false)

  // Watch all values
  const values = Form.useWatch([], form)

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false))
  }, [form, values])

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  )
}

// TODO: proper typing for different fields
type Field<T> = {
  name: NamePath<T>
  label: string
  placeholder: string
  extra?: string
  type?: "text" | "long-text" | "select-one" | "date-range" | "month"
  required?: boolean
  wide?: boolean
  focus?: boolean
  options?: (appState: AppState) => Array<{ value: string; label: string }>
}

type CreateItemFormPropsV2<
  DATA_ITEM extends DataItem,
  FORM_ITEM extends ProjectFormData,
  CREATE_REQUEST extends object,
  UPDATE_REQUEST extends Versioned,
> = {
  fields: ReadonlyArray<Field<FORM_ITEM>>
  itemsKey: keyof AppState
  toForm: (item: DATA_ITEM, appState: AppState) => FORM_ITEM
  toCreateRequest: (item: FORM_ITEM, appState: AppState) => CREATE_REQUEST
  toUpdateRequest: (
    item: FORM_ITEM,
    version: number,
    appState: AppState,
  ) => UPDATE_REQUEST
}

type ItemFormProps = {
  itemId?: string
}

const allocationOptions = [
  {
    label: "100% - Full-time",
    value: 100,
  },
  {
    label: "80% - 4 days a week",
    value: 80,
  },
  {
    label: "60% - 3 days a week",
    value: 60,
  },
  {
    label: "40% - 2 days a week",
    value: 40,
  },
  {
    label: "20% - 1 days a week",
    value: 20,
  },
]

type KeywordOptionType = { id: string; name: string; visible: boolean }

type KeywordCategoryOptionType = {
  keywords: ReadonlyArray<KeywordOptionType>
  id?: string
  name: string
  visible: boolean
}

export const projectFormDetails = <
  DATA_ITEM extends Project,
  FORM_ITEM extends ProjectFormData,
  CREATE_REQUEST extends object,
  UPDATE_REQUEST extends Versioned,
>(
  props: CreateItemFormPropsV2<
    DATA_ITEM,
    FORM_ITEM,
    CREATE_REQUEST,
    UPDATE_REQUEST
  >,
): React.FC<ItemFormProps> => {
  let currentRow = new Array<Field<FORM_ITEM>>()
  const fieldRows = new Array(currentRow)

  props.fields.forEach((field) => {
    if (field.wide) {
      currentRow = [field]
      fieldRows.push(currentRow)
    } else {
      currentRow.push(field)
    }
  })

  // TODO: Add auto focus to the first field
  const CreateItemForm: React.FC<ItemFormProps> = (
    formProps: ItemFormProps,
  ) => {
    const drawerState = useContext(AppDrawerContext)
    const { appState, dispatchAppState } = useAppContext()
    const { notification } = App.useApp()
    const api = useContext(ApiContext)
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [initialValues, setInitialValues] = useState<FORM_ITEM | undefined>()
    const [existingItem, setExistingItem] = useState<DATA_ITEM | undefined>()
    const [form] = Form.useForm()
    const [keywordCategories, setKeywordCategories] = useState<
      ReadonlyArray<KeywordCategoryOptionType>
    >([])
    const [matchingKeywordCategories, setMatchingKeywordCategories] = useState<
      ReadonlyArray<KeywordCategoryOptionType>
    >([])

    const rolesFields = Form.useWatch("roles", { form, preserve: true })

    const employeeOptions = (appState.employees.data ?? []).map(
      ({ id, firstName, lastName }) => ({
        value: id,
        label: `${lastName} ${firstName}`,
      }),
    )

    useEffect(() => {
      const unknownKeywordCategory = {
        id: undefined,
        name: "[Unspecified]",
      }

      const keywords = appState.keywords.data ?? []

      const categories: ReadonlyArray<KeywordCategoryOptionType> = [
        ...(appState["keyword-categories"].data ?? []),
        unknownKeywordCategory,
      ]
        .map((keywordCategory) => {
          return {
            ..._.pick(keywordCategory, "id", "name"),
            visible: true,
            keywords: keywords
              .filter((keyword) => {
                return keywordCategory.id
                  ? keyword.categoryId === keywordCategory.id
                  : !keyword.categoryId
              })
              .map((keyword) => ({
                ..._.pick(keyword, "id", "name"),
                visible: true,
              }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          }
        })
        .filter((keywordCategory) => keywordCategory.keywords.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))

      setKeywordCategories(categories)
      setMatchingKeywordCategories(categories)
    }, [appState])

    const roleOptions = (rolesFields ?? []).map((role: ProjectRole) => ({
      value: role.id,
      label: role.name,
    }))

    // TODO: Remove casts
    const crud = api[props.itemsKey as keyof Api] as unknown as HttpClientV2<
      DATA_ITEM,
      CREATE_REQUEST,
      UPDATE_REQUEST
    >

    const fetchData = () => {
      if (!formProps.itemId) {
        setInitialLoading(false)
        return
      }

      crud.getById(formProps.itemId).then((item) => {
        const initialForm = props.toForm(item, appState)
        setInitialValues(initialForm)
        setExistingItem(item)
        setInitialLoading(false)
      })
    }

    useEffect(() => {
      fetchData()
    }, [])

    useEffect(() => {
      const handleKeyPress = (event: any) => {
        if (event.metaKey === true) {
          if (event.key === "Enter") {
            form.submit()
          }
        }
      }

      // attach the event listener
      document.addEventListener("keydown", handleKeyPress)

      // remove the event listener
      return () => {
        document.removeEventListener("keydown", handleKeyPress)
      }
    })

    const onFinish: FormProps<FORM_ITEM>["onFinish"] = (values) => {
      console.log(JSON.stringify(values, null, 2))
      setLoading(true)

      const operation = () => {
        if (existingItem) {
          const request = props.toUpdateRequest(
            values,
            existingItem.version,
            appState,
          )
          console.log("Update request")
          console.log(JSON.stringify(request, null, 2))

          return crud.update(existingItem.id, request)
        } else {
          const request = props.toCreateRequest(values, appState)
          console.log("Create request")
          console.log(JSON.stringify(request, null, 2))

          return crud.create(request)
        }
      }

      operation()
        .then((data) => {
          dispatchAppState({
            data: [data],
            action: "update-data-items",
            key: props.itemsKey,
          })

          notification.success({
            message: existingItem ? "Update succeeded!" : "Create succeeded!",
          })
          drawerState.close()
          setLoading(false)
        })
        .catch((err) => {
          notification.error({
            message: existingItem ? "Update failed!" : "Create failed!",
          })
          setLoading(false)
        })
    }

    const onFinishFailed: FormProps<ProjectFormData>["onFinishFailed"] = (
      errorInfo,
    ) => {
      // console.log("Failed:", errorInfo)
    }

    const rows = fieldRows.map((fields, rowIndex) => {
      const cols = fields.map((field, fieldIndex) => {
        const span = field.wide ? 24 : 12

        let input = <Input placeholder={field.placeholder} />
        switch (field.type ?? "text") {
          case "text":
            input = <Input placeholder={field.placeholder} />
            break
          case "long-text":
            input = <Input.TextArea rows={10} placeholder={field.placeholder} />
            break
          case "date-range":
            input = (
              <DatePicker.RangePicker
                placeholder={["Start Date", "End Date"]}
                allowEmpty={[!field.required, true]}
                style={{ width: "100%" }}
              />
            )
            break
          case "month":
            input = (
              <DatePicker
                picker="month"
                placeholder={field.placeholder}
                format="YYYY / MM"
                style={{ width: "100%" }}
              />
            )
            break
          case "select-one":
            const options = field.options ? field.options(appState) : []

            input = (
              <Select
                options={options}
                placeholder={field.placeholder}
                optionFilterProp="label"
                showSearch
              />
            )
            break
          default:
            throw new Error(`Unknown field type: ${field.type}`)
        }

        return (
          <Col span={span} key={fieldIndex}>
            <Form.Item<FORM_ITEM>
              name={field.name}
              label={field.label}
              extra={field.extra}
              rules={[{ required: field.required }]}
            >
              {input}
            </Form.Item>
          </Col>
        )
      })

      return (
        <Row gutter={16} key={rowIndex}>
          {cols}
        </Row>
      )
    })

    const projectKeywordQueryChanged: ChangeEventHandler<HTMLInputElement> = ({
      target,
    }) => {
      const query = target.value.trim().toLowerCase()

      console.log(query)

      const updatedMatchingKeywordCategories = keywordCategories
        .map((category) => {
          const keywords = category.keywords.map((keyword) => ({
            ...keyword,
            visible: keyword.name.toLowerCase().includes(query),
          }))

          return {
            ...category,
            visible: keywords.some((keyword) => keyword.visible),
            keywords,
          }
        })
        .filter((category) => category.keywords.length > 0)

      setMatchingKeywordCategories(updatedMatchingKeywordCategories)
    }

    if (initialLoading) {
      return <Skeleton active />
    }

    return (
      <Form
        form={form}
        clearOnDestroy
        disabled={loading || initialLoading}
        layout="vertical"
        requiredMark={false}
        scrollToFirstError
        autoComplete="off"
        name="validateOnly"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        {rows}
        <Row gutter={16}>
          <Col span={24}>
            <Divider orientation="left" orientationMargin={0}>
              Project keywords
            </Divider>
            <Typography.Text type="secondary">
              Project keywords. Will be inherited to project roles.
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ paddingTop: "8px" }}>
            <Input
              placeholder="Type keyword name to limit the number of visible keywords"
              onChange={projectKeywordQueryChanged}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ marginTop: "20px" }}>
            {matchingKeywordCategories.map((category) => {
              return (
                <div
                  key={String(category.id)}
                  style={{
                    display: category.visible ? "inherit" : "none",
                    marginTop: "8px",
                  }}
                >
                  <Typography.Text strong>{category.name}</Typography.Text>
                  <Row>
                    {category.keywords.map((keyword) => (
                      <Col
                        span={8}
                        key={keyword.id}
                        style={{
                          display: keyword.visible ? "inherit" : "none",
                        }}
                      >
                        <Form.Item
                          name={["keywords", keyword.id]}
                          valuePropName="checked"
                          style={{ marginBottom: 0 }}
                        >
                          <Checkbox style={{ width: "100%" }}>
                            {keyword.name}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </div>
              )
            })}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Divider orientation="left" orientationMargin={0}>
              Project Roles
            </Divider>
            <Typography.Text type="secondary">
              Roles for project assignments, e.g. architect, developer.
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ marginTop: "20px" }}>
            <Form.List name="roles">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      type="inner"
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      }
                    >
                      <Form.Item
                        label="Role Name"
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Role Name" />
                      </Form.Item>
                      <Form.Item
                        label="Role Description"
                        name={[field.name, "description"]}
                        rules={[{ required: false }]}
                      >
                        <Input.TextArea
                          rows={5}
                          placeholder="Short description for the role, e.g. tasks and responsibilities in the project."
                        />
                      </Form.Item>
                      {/*<Form.Item*/}
                      {/*  label="Role Keywords"*/}
                      {/*  name={[field.name, "keywords"]}*/}
                      {/*></Form.Item>*/}
                    </Card>
                  ))}
                  <Button type="dashed" onClick={() => add({ id: id() })} block>
                    + Add Role
                  </Button>
                </div>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Divider orientation="left" orientationMargin={0}>
              Project Assignments
            </Divider>
            <Typography.Text type="secondary">
              Employees working for the project.
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ marginTop: "20px" }}>
            <Form.List name="assignments">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      type="inner"
                      key={field.key}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name)
                          }}
                        />
                      }
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Role"
                            name={[field.name, "roleId"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Role"
                              options={roleOptions}
                              optionFilterProp="label"
                              showSearch
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Employee"
                            name={[field.name, "employeeId"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Employee"
                              options={employeeOptions}
                              optionFilterProp="label"
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Assignment Start Date"
                            name={[field.name, "startDate"]}
                          >
                            <DatePicker
                              picker="month"
                              placeholder="Assignment Start Date"
                              format="YYYY / MM"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Assignment End Date"
                            name={[field.name, "endDate"]}
                          >
                            <DatePicker
                              picker="month"
                              placeholder="Assignment End Date"
                              format="YYYY / MM"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Allocation"
                            name={[field.name, "allocation"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Allocation"
                              options={allocationOptions}
                              optionFilterProp="label"
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}

                  {roleOptions.length > 0 ? (
                    <Button
                      type="dashed"
                      onClick={() => add({ id: id(), allocation: 100 })}
                      block
                    >
                      + Add Assignment
                    </Button>
                  ) : (
                    <Alert
                      message="At least one role is required to add assignments"
                      type="info"
                    />
                  )}
                </div>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item style={{ paddingTop: "20px" }}>
              <Space>
                <SubmitButton form={form}>Save (cmd + Enter)</SubmitButton>
                <Button onClick={() => drawerState.close()}>
                  Cancel (Esc)
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Typography.Text type="secondary">
              {existingItem
                ? `Last modified by ${
                    existingItem.modifiedBy
                  } at ${formatLocalTimestamp(existingItem.modifiedAt)}`
                : ""}
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    )
  }

  return CreateItemForm
}
