import {
  CreateVerticalRequest,
  UpdateVerticalRequest,
  Vertical,
  VerticalBase,
} from "../../../../common/model/vertical"
import { dataItemForm } from "./DataItemForm"

export const VerticalForm = dataItemForm<
  Vertical,
  VerticalBase,
  CreateVerticalRequest,
  UpdateVerticalRequest
>({
  itemsKey: "verticals",
  fields: [
    {
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
      focus: true,
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
