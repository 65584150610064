import { Typography } from "antd"
import { Contact } from "../../../common/model/contacts"
import { ContactForm } from "../components/form/ContactForm"
import { UploadForm } from "../components/form/UploadForm"
import { resolveClientName } from "../components/render/ClientName"
import { ContactListItem } from "../model/contacts"
import { ContactDetailsView } from "./ContactDetailsView"
import { listingView } from "./ListingView"

export const ContactsView = listingView<Contact, ContactListItem>({
  itemsKey: "contacts",
  addItemTitle: "New Contact",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected contacts?",
  addItemForm: <ContactForm />,
  editItemForm: (item) => <ContactForm itemId={item.id} />,
  detailsView: (item) => <ContactDetailsView itemId={item.id} />,
  actions: ["remove"],
  uploadItemsForm: (
    <UploadForm
      itemsKey="contacts"
      examples={[
        {
          description: (
            <Typography.Text>
              Import contacts without the <code>id</code> column. Should be used
              when creating new contacts.
            </Typography.Text>
          ),
          rows: [
            "email, firstName, lastName, current, vendor",
            "john.doe@company.com, John, Doe, admin",
            "albert.einstein@company.com, Albert, Einstein, sales",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import contacts with the <code>id</code> column. Should be used
              when modifying existing contacts. Note that the <code>id</code>{" "}
              column can be empty.
            </Typography.Text>
          ),
          rows: [
            "email, firstName, lastName, current, vendor, id",
            "john.doe@company.com, John, Doe, true, Webscale Oy, caa65962-1950-4e9d-b1c4-ffc52fae0643",
            "albert.einstein@company.com, Albert, Einstein, false, Webscale Oy, b27e5dec-6ddb-4b92-85e7-8549cce9c4a0",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "email",
              required: true,
              description: (
                <Typography.Text>
                  Contact email address. Used to find an existing contact to
                  update if the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "firstName",
              required: true,
              description: "Contact first name.",
            },
            {
              name: "lastName",
              required: true,
              description: "Contact last name.",
            },
            {
              name: "current",
              required: true,
              description: (
                <Typography.Text>
                  Is the contact currently working for the vendor? Allowed
                  values are:
                  <ul>
                    <li>true = Contact is currently working for the vendor.</li>
                    <li>
                      false = Contact is not currently working for the vendor.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "vendor",
              description:
                "Name of the vendor for whom the contact works for. If no vendor exists with the given name, a new vendor will be created.",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Contact id. Used to find an existing contact to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update email
                      address of an existing contact.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  columns: [
    {
      property: "email",
      title: "Email",
      width: "400px",
    },
    {
      property: "firstName",
      title: "First Name",
      width: "300px",
    },
    {
      property: "lastName",
      title: "Last Name",
      width: "300px",
    },
  ],
  toListItem: (item, appState) => {
    const clientName = resolveClientName(appState, item.clientId)
    return {
      ...item,
      clientName,
      text: [
        item.email,
        item.lastName,
        item.firstName,
        item.phone,
        clientName,
      ].join(" "),
    }
  },
})
