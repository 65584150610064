import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from "react"
import { Client } from "../../../common/model/clients"
import { DataItem } from "../../../common/model/common"
import { Contact } from "../../../common/model/contacts"
import { Employee } from "../../../common/model/employees"
import { KeywordCategory } from "../../../common/model/keyword-categories"
import { Keyword } from "../../../common/model/keywords"
import { Project } from "../../../common/model/projects"
import { User } from "../../../common/model/users"
import { Vendor } from "../../../common/model/vendor"
import { Vertical } from "../../../common/model/vertical"

//
// Application state
//
export type State<T extends DataItem> = {
  data?: ReadonlyArray<T>
  loading: boolean
}

export type AppState = {
  clients: State<Client>
  keywords: State<Keyword>
  "keyword-categories": State<KeywordCategory>
  employees: State<Employee>
  projects: State<Project>
  verticals: State<Vertical>
  vendors: State<Vendor>
  users: State<User>
  contacts: State<Contact>
}

const initialAppState: AppState = {
  clients: { loading: true },
  keywords: { loading: true },
  "keyword-categories": { loading: true },
  employees: { loading: true },
  projects: { loading: true },
  verticals: { loading: true },
  vendors: { loading: true },
  users: { loading: true },
  contacts: { loading: true },
}

type UpdateDataAction<T extends DataItem> = {
  action: "update-data"
  key: keyof AppState
  data?: ReadonlyArray<T>
  stage: "start" | "complete"
}

type UpdateDataItemsAction<T extends DataItem> = {
  action: "update-data-items"
  key: keyof AppState
  data: ReadonlyArray<T>
}

type RemoveDataItemsAction = {
  action: "remove-data-items"
  key: keyof AppState
  ids: ReadonlyArray<string>
}

type AppStateAction =
  | UpdateDataAction<DataItem>
  | UpdateDataItemsAction<DataItem>
  | RemoveDataItemsAction

const removeItems = (
  currentItems: ReadonlyArray<DataItem>,
  ids: ReadonlyArray<string>,
): ReadonlyArray<DataItem> =>
  currentItems.filter((item) => !ids.includes(item.id))

const updateItems = (
  currentItems: ReadonlyArray<DataItem>,
  updatedItems: ReadonlyArray<DataItem>,
): ReadonlyArray<DataItem> => {
  const updated = currentItems.slice()
  updatedItems.forEach((item) => {
    const index = currentItems.findIndex((current) => current.id === item.id)
    if (index !== -1) {
      updated[index] = item
    } else {
      updated.push(item)
    }
  })

  return updated
}

export const appStateReducer = (
  state: AppState,
  action: AppStateAction,
): AppState => {
  switch (action.action) {
    case "update-data-items":
      return {
        ...state,
        [action.key]: {
          loading: false,
          data: updateItems(state[action.key].data ?? [], action.data),
        },
      }
    case "remove-data-items":
      return {
        ...state,
        [action.key]: {
          loading: false,
          data: removeItems(state[action.key].data ?? [], action.ids),
        },
      }
    case "update-data":
      if (action.stage === "start") {
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            loading: true,
          },
        }
      } else if (action.stage === "complete") {
        const currentData = state[action.key].data
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            data: action.data ?? currentData,
            loading: false,
          },
        }
      }

      throw new Error(
        `Unknown stage ${action.stage} in action ${action.action}`,
      )

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

// Define the type for your context data
type AppContextType = {
  appState: AppState
  dispatchAppState: Dispatch<AppStateAction>
}

// Create the context with an initial value of null
export const AppContext = createContext<AppContextType | undefined>(undefined)

// Define the props type for the context provider component
type ContextProviderProps = {
  children: ReactNode
}

// Define the provider component
const AppContextProvider = ({ children }: ContextProviderProps) => {
  const [appState, dispatchAppState] = useReducer(
    appStateReducer,
    initialAppState,
  )

  return (
    <AppContext.Provider value={{ appState, dispatchAppState }}>
      {children}
    </AppContext.Provider>
  )
}

//
// Hooks
//

// Hook to use the context
export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error("The App Context must be used within an AppContextProvider")
  }

  return context
}

export default AppContextProvider
