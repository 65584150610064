import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type KeywordNameProps = {
  id: string
  defaultValue?: string | false
}

export const resolveKeywordName = (
  appState: AppState,
  keywordId?: string,
  defaultValue: string | false = "-",
): string => {
  if (!keywordId) {
    return "-"
  }

  const keyword = appState["keywords"].data?.find((v) => v.id === keywordId)

  if (keyword) {
    return keyword.name
  }

  if (defaultValue === false) {
    return keywordId
  }

  return defaultValue
}

export const KeywordName: React.FC<KeywordNameProps> = ({
  id,
  defaultValue,
}: KeywordNameProps) => {
  const { appState } = useAppContext()
  return <>{resolveKeywordName(appState, id, defaultValue)}</>
}
