import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type ReferenceNameProps = {
  id: string
}

export const resolveVerticalName = (
  appState: AppState,
  verticalId?: string,
): string => {
  if (!verticalId) {
    return "-"
  }

  const vertical = appState.verticals.data?.find((v) => v.id === verticalId)
  return vertical?.name ?? "-"
}

export const VerticalName: React.FC<ReferenceNameProps> = ({
  id,
}: ReferenceNameProps) => {
  const { appState } = useAppContext()
  return <>{resolveVerticalName(appState, id)}</>
}
