import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type ClientNameNameProps = {
  id: string
}

export const resolveClientName = (
  appState: AppState,
  clientId?: string,
): string => {
  if (!clientId) {
    return "-"
  }

  const client = appState.clients.data?.find((v) => v.id === clientId)
  return client?.name ?? "-"
}

export const ClientName: React.FC<ClientNameNameProps> = ({
  id,
}: ClientNameNameProps) => {
  const { appState } = useAppContext()
  return <>{resolveClientName(appState, id)}</>
}
