import { Typography } from "antd"
import dayjs from "dayjs"
import _ from "lodash"
import { Project } from "../../../common/model/projects"
import { ProjectForm } from "../components/form/ProjectForm"
import { UploadForm } from "../components/form/UploadForm"
import { resolveClientName } from "../components/render/ClientName"
import { resolveEmployeeName } from "../components/render/EmployeeName"
import { resolveVendorName } from "../components/render/VendorName"
import { ProjectListItem } from "../model/projects"
import { listingView } from "./ListingView"
import { ProjectDetailsView } from "./ProjectDetailsView"

export const ProjectsView = listingView<Project, ProjectListItem>({
  itemsKey: "projects",
  addItemTitle: "New Project",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected projects?",
  addItemForm: <ProjectForm />,
  editItemForm: (item) => <ProjectForm itemId={item.id} />,
  detailsView: (item) => <ProjectDetailsView itemId={item.id} />,
  uploadItemsForm: (
    <UploadForm
      itemsKey="projects"
      dependencies={["clients", "vendors"]}
      instructions={
        <Typography.Text>
          Choose a <strong>.csv</strong> file to import data into the system.
          The file can have different types of data. Use the <code>_type</code>{" "}
          column to indicate what kind of data each row contains.
        </Typography.Text>
      }
      examples={[
        {
          description: (
            <Typography.Text>
              Import projects without roles and assignments, and without the{" "}
              <code>id</code> column. Should be used when creating new projects.
            </Typography.Text>
          ),
          rows: [
            "name, code, client, vendor, descriptiom, keywords, startDate, endDate, _type",
            'Cloud Ops, OPS, Customer Inc., Webscale Oy, Cloud operations project, "CloudFormation, AWS", 2024-01-01, 2024-12-31, project',
            'Maintenance, MNT, Acme, Webscale Oy, Maintenance project, "Terraform, Azure, Git", 2024-05-01, 2024-12-31, project',
          ],
        },
        {
          description: (
            <Typography.Text>
              Import projects without roles and assignments, and with the{" "}
              <code>id</code> column. Should be used when modifying existing
              projects. Note that the <code>id</code> column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, code, client, vendor, description, keywords, startDate, endDate, _type, id",
            'Cloud Ops, OPS, Customer Inc., Webscale Oy, Cloud operations project, "CloudFormation, AWS", 2024-01-01, 2024-12-31, project, a39b33ef-c2d4-4100-9f27-06d801a473f3',
            'Maintenance, MNT, Acme, Webscale Oy, Maintenance project, "Terraform, Azure, Git", 2024-05-01, 2024-12-31, project, 0acc58a2-0764-4056-ab00-9541522d4053',
          ],
        },
        {
          description: (
            <Typography.Text>
              Import projects with roles and assignments, and with the{" "}
              <code>id</code> column. Note the additional columns that are
              needed with roles and assignments, and how the <code>_type</code>{" "}
              column is used to separate rows representing roles and assignments
              from the ones representing project basic info.
            </Typography.Text>
          ),
          rows: [
            "name, code, client, vendor, description, keywords, startDate, endDate, _type, id, _projectId, role, allocation, employee",
            'Cloud Ops, OPS, Customer Inc., Webscale Oy, Cloud operations project, "CloudFormation, AWS", 2024-01-01, 2024-12-31, project, a39b33ef-c2d4-4100-9f27-06d801a473f3,,,,',
            'Cloud Engineer,,,,Deploys stuff to cloud,"IaC, AWS",,,role,,0acc58a2-0764-4056-ab00-9541522d4053,,,',
            ",,,,,,,,assignment,,0acc58a2-0764-4056-ab00-9541522d4053, Cloud Engineer, 100, gandalf.gray@company.com",
            'Maintenance, MNT, Acme, Webscale Oy, Maintenance project, "Terraform, Azure, Git", 2024-05-01, 2024-12-31, project, 0acc58a2-0764-4056-ab00-9541522d4053,,,,',
            'Server Admin,,,,Server maintenance,"TypeScript, AWS",,,role,,0acc58a2-0764-4056-ab00-9541522d4053,,,',
            "Project Manager,,,,Manages the project,,,,role,,0acc58a2-0764-4056-ab00-9541522d4053,,,",
            ",,,,,,,,assignment,,0acc58a2-0764-4056-ab00-9541522d4053, Server Admin, 100, john.doe@company.com",
            ",,,,,,,,assignment,,0acc58a2-0764-4056-ab00-9541522d4053, Project Manager, 50, mary.jane@company.com",
          ],
        },
      ]}
      rowData={[
        {
          title: "Project",
          description: (
            <Typography.Text>
              For each imported project, the file must contain one row of type{" "}
              <strong>project</strong>. This row must contain the project's
              basic information. See the following table for column details.
            </Typography.Text>
          ),
          rows: [
            {
              name: "name",
              required: true,
              description: "Project name",
            },
            {
              name: "code",
              required: true,
              description: (
                <Typography.Text>
                  Unique project code. Used to find an existing project to
                  update if the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "client",
              description:
                "Name of the client the project belongs to. " +
                "If no client exists with the given name, a new client will be created.",
            },
            {
              name: "vendor",
              description:
                "Name of the vendor who completed the project. " +
                "If no vendor exists with the given name, a new vendor will be created.",
            },
            {
              name: "keywords",
              description: "Comma-separated list of project keyword names.",
            },
            {
              name: "startDate",
              description: "Project start date in YYYY-MM-DD format.",
            },
            {
              name: "endDate",
              description: "Project end date in YYYY-MM-DD format.",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Project id. Used to find an existing project to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update code of an
                      existing project.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "_type",
              required: true,
              description: (
                <Typography.Text>
                  The value must be <strong>project</strong>.
                </Typography.Text>
              ),
            },
          ],
        },
        {
          title: "Role",
          description: (
            <Typography.Text>
              For each imported project, the file can contain any number of rows
              of type <strong>role</strong>. These rows must contain information
              about the project roles. See the following table for column
              details.
            </Typography.Text>
          ),
          rows: [
            {
              name: "name",
              required: true,
              description: "Role name",
            },
            {
              name: "description",
              description: "Role description",
            },
            {
              name: "keywords",
              description: "Comma-separated list of project keyword names.",
            },
            {
              name: "_projectId",
              description: (
                <Typography.Text>
                  Project id. Used to match the role to an existing project.
                  <ul>
                    <li>
                      You must specify either the project id or project code.
                    </li>
                    <li>
                      Import will ignore the row if you specify a project id
                      that is not found from the import file.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "_projectCode",
              description: (
                <Typography.Text>
                  Project code. Used to match the role to an existing project.
                  <ul>
                    <li>
                      You must specify either the project code or project id.
                    </li>
                    <li>
                      Import will ignore the row if you specify a project code
                      that is not found from the import file.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "_type",
              required: true,
              description: (
                <Typography.Text>
                  The value must be <strong>role</strong>.
                </Typography.Text>
              ),
            },
          ],
        },
        {
          title: "Assignment",
          description: (
            <Typography.Text>
              For each imported project, the file can contain any number of rows
              of type <strong>assignment</strong>. These rows must contain
              information about the project assignments. See the following table
              for column details.
            </Typography.Text>
          ),
          rows: [
            {
              name: "role",
              required: true,
              description: (
                <Typography.Text>
                  Name of the role. Used to match the assignment to a project
                  role.
                  <ul>
                    <li>
                      Import will ignore the row if you specify a role that is
                      not found from the import file.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "employee",
              required: true,
              description: (
                <Typography.Text>
                  Email address of the assigned employee. Used to match the
                  assignment to an employee.
                  <ul>
                    <li>
                      Import will fail if you specify an employee that is not
                      found from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "allocation",
              required: true,
              description: "Assignment allocation",
            },
            {
              name: "startDate",
              description: "Assignment start date in YYYY-MM-DD format.",
            },
            {
              name: "endDate",
              description: "Assignment end date in YYYY-MM-DD format.",
            },
            {
              name: "_projectId",
              description: (
                <Typography.Text>
                  Project id. Used to match the role to an existing project.
                  <ul>
                    <li>
                      You must specify either the project id or project code.
                    </li>
                    <li>
                      Import will ignore the row if you specify a project id
                      that is not found from the import file.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "_projectCode",
              description: (
                <Typography.Text>
                  Project code. Used to match the role to an existing project.
                  <ul>
                    <li>
                      You must specify either the project code or project id.
                    </li>
                    <li>
                      Import will ignore the row if you specify a project code
                      that is not found from the import file.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
            {
              name: "_type",
              required: true,
              description: (
                <Typography.Text>
                  The value must be <strong>assignment</strong>.
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  actions: ["remove"],
  columns: [
    {
      property: "name",
      title: "Name",
      width: "400px",
    },
    {
      property: "code",
      title: "Code",
      width: "150px",
    },
    {
      property: "startDate",
      title: "Start Date",
      displayValue: (appState, item) =>
        item.startDate ? dayjs(item.startDate).format("YYYY / MM") : "-",
    },
    {
      property: "endDate",
      title: "End Date",
      displayValue: (appState, item) =>
        item.endDate ? dayjs(item.endDate).format("YYYY / MM") : "-",
    },
    {
      title: "Client",
      property: "clientId",
      displayValue: (appState, item) =>
        resolveClientName(appState, item.clientId),
      filter: true,
      width: "300px",
    },
    {
      title: "Employees",
      property: "employeeNames",
      displayValue: (appState, item) => item.employeeNames?.join(", ") ?? "-",
      filter: true,
      width: "400px",
    },
    {
      title: "Vendor",
      property: "vendorId",
      displayValue: (appState, item) =>
        resolveVendorName(appState, item.vendorId),
      filter: true,
      width: "300px",
    },
  ],
  toListItem: (item, appState) => {
    const clientName = resolveClientName(appState, item.clientId)
    const vendorName = resolveVendorName(appState, item.vendorId)
    const employeeNames = _.uniq(
      item.assignments?.map((a) => resolveEmployeeName(appState, a.employeeId)),
    ).sort((a, b) => a.localeCompare(b))

    return {
      ...item,
      clientName,
      vendorName,
      employeeNames,
      text: [item.name, clientName, vendorName].join(" ").toLowerCase(),
    }
  },
})
