import {
  CreateVendorRequest,
  UpdateVendorRequest,
  Vendor,
  VendorBase,
} from "../../../../common/model/vendor"
import { dataItemForm } from "./DataItemForm"

export const VendorForm = dataItemForm<
  Vendor,
  VendorBase,
  CreateVendorRequest,
  UpdateVendorRequest
>({
  itemsKey: "vendors",
  fields: [
    {
      name: "name",
      label: "Name",
      placeholder: "Name",
      required: true,
      wide: true,
      focus: true,
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
