import { Space, Typography } from "antd"
import { User } from "../../../common/model/users"
import { UserForm } from "../components/form/UserForm"
import { detailsView } from "./DetailsView"

export const UserDetailsView = detailsView<User>({
  itemsKey: "users",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <UserForm itemId={item.id} onCancel={onCancel} onComplete={onComplete} />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>First Name</Typography.Text>
        <Typography.Text>{item.firstName}</Typography.Text>
        <Typography.Text strong>Last Name</Typography.Text>
        <Typography.Text>{item.lastName}</Typography.Text>
        <Typography.Text strong>Email</Typography.Text>
        <Typography.Text>{item.email}</Typography.Text>
        <Typography.Text strong>Role</Typography.Text>
        <Typography.Text>{item.role}</Typography.Text>
      </Space>
    )
  },
})
