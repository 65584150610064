import { UploadOutlined } from "@ant-design/icons"
import {
  App,
  Button,
  Col,
  Form,
  Row,
  Space,
  Table,
  Typography,
  Upload,
  UploadProps,
} from "antd"
import _ from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { Api } from "../../api"
import { HttpClientV2 } from "../../api/http-client"
import { ApiContext } from "../../ApiContext"
import { getConfig } from "../../config"
import { AppState, useAppContext } from "../../context/AppContext"
import { useUserSessionContext } from "../../context/UserSessionContext"
import { AppDrawerContext } from "../AppDrawer"

type RowDataSpecification = {
  name: string
  description: string | React.ReactNode
  required?: boolean
}

type RowDataSpecifications = {
  title?: string
  description?: string | React.ReactNode
  rows: ReadonlyArray<RowDataSpecification>
}

type UploadFormProps = {
  itemsKey: keyof AppState
  instructions?: React.ReactNode
  rowData: ReadonlyArray<RowDataSpecifications>
  examples?: ReadonlyArray<{
    title?: string
    description?: React.ReactNode
    rows: ReadonlyArray<string>
  }>
  dependencies?: ReadonlyArray<keyof AppState>
}

export const UploadForm: React.FC<UploadFormProps> = (
  props: UploadFormProps,
) => {
  const api = useContext(ApiContext)
  const { dispatchAppState } = useAppContext()
  const { notification } = App.useApp()
  const drawerState = useContext(AppDrawerContext)
  const [form] = Form.useForm()
  const {
    userSession: { accessTokenProvider },
  } = useUserSessionContext()
  const [uploadProps, setUploadProps] = useState<UploadProps>({
    disabled: true,
  })
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    const { uploadBasePath } = getConfig(window.location.hostname)

    accessTokenProvider().then((token) => {
      const p: UploadProps = {
        name: "file",
        action: `${uploadBasePath}/api/${props.itemsKey}/import`,
        beforeUpload: () => {
          setUploading(true)
          return true
        },
        disabled: false,
        withCredentials: true,
        maxCount: 1,
        showUploadList: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onChange: (info) => {
          if (info.file.status === "uploading") {
            // console.log(info.file, info.fileList)
          }
          if (info.file.status === "done") {
            // console.log(JSON.stringify(info))

            console.log(JSON.stringify(info, undefined, 2))

            const results = info.file.response.data.results as ReadonlyArray<{
              rowNumber: number
              status: "failed" | "created" | "updated" | "not-changed"
              message: string
            }>

            const failedItems = results.filter((r) => r.status === "failed")
            const notChangedItems = results.filter(
              (r) => r.status === "not-changed",
            )
            const createdItems = results.filter((r) => r.status === "created")
            const updatedItems = results.filter((r) => r.status === "updated")

            const description = (
              <span>
                Summary of the import operation:
                <ul>
                  <li>{createdItems.length} created</li>
                  <li>{updatedItems.length} updated</li>
                  <li>{notChangedItems.length} unchanged</li>
                  <li>{failedItems.length} failed items</li>
                </ul>
              </span>
            )

            const keysToRefresh = _.uniq([
              props.itemsKey,
              ...(props.dependencies ?? []),
            ])

            Promise.all(
              keysToRefresh.map(async (key) => {
                dispatchAppState({
                  key,
                  action: "update-data",
                  stage: "start",
                })

                // TODO: Remove casts
                const crud = api[key as keyof Api] as unknown as HttpClientV2<
                  any,
                  any,
                  any
                >

                return { key, data: await crud.list() }
              }),
            ).then((results) => {
              results.forEach(({ key, data }) => {
                dispatchAppState({
                  action: "update-data",
                  stage: "complete",
                  key,
                  data,
                })
              })

              drawerState.close()
              notification.success({
                message: "Import completed",
                description,
                showProgress: true,
                pauseOnHover: true,
              })
              setUploading(false)
            })
          } else if (info.file.status === "error") {
            notification.error({ message: "Import failed!" })
            setUploading(false)
          }
        },
      }

      setUploadProps(p)
    })
  }, [
    accessTokenProvider,
    notification,
    dispatchAppState,
    api,
    drawerState,
    props.dependencies,
    props.itemsKey,
  ])

  const columns = [
    { title: "Column Name", dataIndex: "name", key: "name" },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
      render: (value: boolean) => (value ? "Yes" : "No"),
    },
    { title: "Description", dataIndex: "description", key: "description" },
  ]

  const instructions = props.instructions ?? (
    <Typography.Text>
      Choose a <strong>.csv</strong> file to import data into the system. The
      rows in the file must follow the following structure:
    </Typography.Text>
  )

  const columnsSpecifications = props.rowData.map((rowData) => {
    const title = rowData.title ? (
      <Typography.Title level={4}>{rowData.title}</Typography.Title>
    ) : undefined

    return (
      <div>
        {title}
        {rowData.description}
        <Table
          rowHoverable={false}
          rowKey={(row) => row.name}
          style={{ marginTop: "20px" }}
          dataSource={rowData.rows}
          columns={columns}
          pagination={false}
        />
      </div>
    )
  })

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }}>
        {instructions}
        {columnsSpecifications}
        <Typography.Title level={4}>Examples</Typography.Title>
        {props.examples?.map((example, index) => {
          return (
            <div key={index}>
              {example.title ? (
                <Typography.Title level={5}>{example.title}</Typography.Title>
              ) : undefined}
              {example.description ? (
                <Typography.Text>{example.description}</Typography.Text>
              ) : undefined}
              <pre
                style={{
                  counterReset: "line",
                  background: "#fafafa",
                  padding: "4px",
                  overflow: "scroll",
                }}
              >
                {example.rows.map((row, rowIndex) => (
                  <code className="example" key={rowIndex}>
                    {row}
                  </code>
                ))}
              </pre>
            </div>
          )
        })}
      </Space>
      <Form
        form={form}
        clearOnDestroy
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        style={{ marginTop: "20px" }}
        name="validateOnly"
      >
        <Row gutter={16}>
          <Col span={25}>
            <Form.Item name="import-button">
              <Space>
                <Upload {...uploadProps}>
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    disabled={uploading}
                  >
                    Choose file
                  </Button>
                </Upload>
                <Button
                  onClick={() => drawerState.close()}
                  disabled={uploading}
                >
                  Cancel (Esc)
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
