import { Button, Layout, Space, Tabs, TabsProps, theme, Typography } from "antd"
import { Auth } from "aws-amplify"
import React, { useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { PermissionScope } from "../../common/model/permissions"
import { ApiContext } from "./ApiContext"
import { BuildInfo } from "./build-info"
import {
  AppDrawer,
  AppDrawerContext,
  AppDrawerProps,
  AppDrawerState,
} from "./components/AppDrawer"
import { RESOURCE_NAMES } from "./constants"
import { useAppContext } from "./context/AppContext"
import { useUserSessionContext } from "./context/UserSessionContext"

type QuestTab = { key: string; label: string; scope: PermissionScope }

const allTabs: ReadonlyArray<QuestTab> = [
  {
    key: "search",
    label: "Search",
    scope: "quest.projects.read",
  },
  {
    key: "projects",
    label: "Projects",
    scope: "quest.projects.write",
  },
  {
    key: "employees",
    label: "Employees",
    scope: "quest.employees.write",
  },
  {
    key: "clients",
    label: "Clients",
    scope: "quest.clients.write",
  },
  {
    key: "keywords",
    label: "Keywords",
    scope: "quest.keywords.write",
  },
  {
    key: "keyword-categories",
    label: "Keyword Categories",
    scope: "quest.keyword-categories.write",
  },
  {
    key: "verticals",
    label: "Verticals",
    scope: "quest.vendors.write",
  },
  {
    key: "vendors",
    label: "Vendors",
    scope: "quest.vendors.write",
  },
  {
    key: "contacts",
    label: "Contacts",
    scope: "quest.contacts.write",
  },
  {
    key: "users",
    label: "Users",
    scope: "quest.users.write",
  },
]

export const AppContent: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const api = useContext(ApiContext)
  const { userSession } = useUserSessionContext()
  const [currentTab, setCurrentTab] = useState<string | undefined>()
  const { dispatchAppState } = useAppContext()

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  const [drawerProps, setDrawerProps] = useState<AppDrawerProps>({
    loading: false,
    open: false,
    title: "",
    content: <div />,
    size: "default",
  })

  const tabs: TabsProps["items"] = allTabs.filter((tab) =>
    userSession.scopes.has(tab.scope),
  )

  useEffect(() => {
    const resourceNames = RESOURCE_NAMES.filter((name) =>
      userSession.scopes.has(`quest.${name}.read`),
    )

    resourceNames.forEach((key) => {
      dispatchAppState({
        key,
        action: "update-data",
        stage: "start",
      })
    })

    Promise.all(
      resourceNames.map((key) =>
        api[key].list().then((data) => {
          dispatchAppState({
            key,
            data,
            action: "update-data",
            stage: "complete",
          })
        }),
      ),
    )
  }, [api, dispatchAppState, userSession.scopes])

  useEffect(() => {
    const key = location.pathname.split("/").slice(-1)[0]
    const activeTab =
      tabs
        .sort((a, b) => (a.key.length > b.key.length ? -1 : 1))
        .find((t) => t.key === key) ?? tabs[0]
    setCurrentTab(activeTab.key)
  }, [location, tabs])

  const appDrawerState: AppDrawerState = {
    close: () => {
      if (drawerProps.onClose) {
        drawerProps.onClose()
      }

      setDrawerProps({ ...drawerProps, open: false })
    },
    open: (props: AppDrawerProps) => {
      setDrawerProps(props)
    },
    setLoading: (loading: boolean) => {
      setDrawerProps({ ...drawerProps, loading })
    },
    props: drawerProps,
  }

  return (
    <AppDrawerContext.Provider value={appDrawerState}>
      <Layout>
        <AppDrawer />
        <Layout.Content style={{ padding: "20px 20px" }}>
          <Tabs
            activeKey={currentTab}
            items={tabs}
            onTabClick={(key) => navigate(key)}
            type="card"
            tabBarStyle={{ marginBottom: 0, marginLeft: "10px" }}
          />
          <div
            style={{
              background: colorBgContainer,
              minHeight: 280,
              padding: "10px",
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </div>
          <Space style={{ marginTop: "5px", marginLeft: "5px" }}>
            <Typography.Text style={{ color: "#B8B8B8" }}>
              Logged in as {userSession.firstName} {userSession.lastName}
            </Typography.Text>
            <Typography.Text style={{ color: "#B8B8B8" }}>::</Typography.Text>
            <Button
              type="text"
              size="small"
              style={{ color: "#B8B8B8" }}
              onClick={() => Auth.signOut()}
            >
              Log Out
            </Button>
            <Typography.Text style={{ color: "#B8B8B8" }}>::</Typography.Text>
            <Typography.Text style={{ color: "#B8B8B8" }}>
              {BuildInfo.version} - {BuildInfo.commit} - {BuildInfo.timestamp}
            </Typography.Text>
          </Space>
        </Layout.Content>
      </Layout>
    </AppDrawerContext.Provider>
  )
}
