import React, { PropsWithChildren } from "react"
import { PermissionScope } from "../../../../common/model/permissions"
import { useUserSessionContext } from "../../context/UserSessionContext"

type AuthorizeProps = {
  scope: PermissionScope
}

export const Authorize: React.FC<PropsWithChildren<AuthorizeProps>> = ({
  scope,
  children,
}) => {
  const { userSession } = useUserSessionContext()

  if (!userSession.scopes.has(scope)) {
    return <></>
  }

  return <>{children}</>
}
