import {
  CreateEmployeeRequest,
  Employee,
  EmployeeBase,
  UpdateEmployeeRequest,
} from "../../../../common/model/employees"
import { dataItemForm } from "./DataItemForm"

export const EmployeeForm = dataItemForm<
  Employee,
  EmployeeBase,
  CreateEmployeeRequest,
  UpdateEmployeeRequest
>({
  itemsKey: "employees",
  fields: [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      required: true,
      focus: true,
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      required: true,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Email",
      required: true,
    },
    {
      name: "current",
      label: "Current",
      placeholder: "Current",
      required: true,
      type: "select-one",
      options: () => [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    {
      name: "vendorId",
      label: "Vendor",
      placeholder: "Vendor",
      type: "select-one",
      options: (appState) =>
        (appState.vendors.data ?? []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
  ],
  toForm: (a) => a,
  toCreateRequest: (a) => a,
  toUpdateRequest: (a, version) => ({ ...a, version }),
})
