import { Space, Typography } from "antd"
import { Employee } from "../../../common/model/employees"
import { EmployeeForm } from "../components/form/EmployeeForm"
import { resolveVendorName } from "../components/render/VendorName"
import { detailsView } from "./DetailsView"

export const EmployeeDetailsView = detailsView<Employee>({
  itemsKey: "employees",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <EmployeeForm
      itemId={item.id}
      onComplete={onComplete}
      onCancel={onCancel}
    />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>First Name</Typography.Text>
        <Typography.Text>{item.firstName}</Typography.Text>
        <Typography.Text strong>Last Name</Typography.Text>
        <Typography.Text>{item.lastName}</Typography.Text>
        <Typography.Text strong>Email</Typography.Text>
        <Typography.Text>{item.email}</Typography.Text>
        <Typography.Text strong>Current</Typography.Text>
        <Typography.Text>{item.current ? "Yes" : "No"}</Typography.Text>
        <Typography.Text strong>Vendor</Typography.Text>
        <Typography.Text>
          {resolveVendorName(appState, item.vendorId)}
        </Typography.Text>
      </Space>
    )
  },
})
