import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type KeywordCategoryNameProps = {
  id: string
}

export const resolveKeywordCategoryName = (
  appState: AppState,
  keywordCategoryId?: string,
): string => {
  if (!keywordCategoryId) {
    return "-"
  }

  const keywordCategory = appState["keyword-categories"].data?.find(
    (v) => v.id === keywordCategoryId,
  )
  return keywordCategory?.name ?? "-"
}

export const KeywordCategoryName: React.FC<KeywordCategoryNameProps> = ({
  id,
}: KeywordCategoryNameProps) => {
  const { appState } = useAppContext()
  return <>{resolveKeywordCategoryName(appState, id)}</>
}
