import { Typography } from "antd"
import { Vendor } from "../../../common/model/vendor"
import { UploadForm } from "../components/form/UploadForm"
import { VendorForm } from "../components/form/VendorForm"
import { VendorListItem } from "../model/vendors"
import { listingView } from "./ListingView"
import { VendorDetailsView } from "./VendorDetailsView"

export const VendorsView = listingView<Vendor, VendorListItem>({
  itemsKey: "vendors",
  addItemTitle: "New Vendor",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected vendors?",
  addItemForm: <VendorForm />,
  editItemForm: (item) => <VendorForm itemId={item.id} />,
  detailsView: (item) => <VendorDetailsView itemId={item.id} />,
  actions: ["remove"],
  uploadItemsForm: (
    <UploadForm
      itemsKey="vendors"
      examples={[
        {
          description: (
            <Typography.Text>
              Import vendors without the <code>id</code> column. Should be used
              when creating new vendors.
            </Typography.Text>
          ),
          rows: ["name", "IT Company", "Consulting Dudes"],
        },
        {
          description: (
            <Typography.Text>
              Import vendors with the <code>id</code> column. Should be used
              when modifying existing vendors. Note that the <code>id</code>{" "}
              column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, id",
            "IT Company, 14a3e998-4ba5-4de4-94ad-ee5f8348f199",
            "Consulting Dudes, 94eff251-de39-4bad-8f2c-05a5a189a988",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "name",
              required: true,
              description: (
                <Typography.Text>
                  Vendor name. Used to find an existing vendor to update if the{" "}
                  <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Vendor id. Used to find an existing vendor to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update name of an
                      existing vendor.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  columns: [
    {
      property: "name",
      title: "Name",
    },
  ],
  toListItem: (item, appState) => ({ ...item, text: item.name.toLowerCase() }),
})
