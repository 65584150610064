import { Space, Typography } from "antd"
import { Client } from "../../../common/model/clients"
import { ClientForm } from "../components/form/ClientForm"
import { resolveVerticalName } from "../components/render/VerticalName"
import { detailsView } from "./DetailsView"

export const ClientDetailsView = detailsView<Client>({
  itemsKey: "clients",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <ClientForm itemId={item.id} onCancel={onCancel} onComplete={onComplete} />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Vertical</Typography.Text>
        <Typography.Text>
          {resolveVerticalName(appState, item.verticalId)}
        </Typography.Text>
      </Space>
    )
  },
})
