import React from "react"
import { AppState, useAppContext } from "../../context/AppContext"

type EmployeeNameProps = {
  id: string
}

export const resolveEmployeeName = (
  appState: AppState,
  employeeId?: string,
): string => {
  if (!employeeId) {
    return "-"
  }

  const employee = appState.employees.data?.find((v) => v.id === employeeId)
  return employee ? `${employee.firstName} ${employee.lastName}` : "-"
}

export const EmployeeName: React.FC<EmployeeNameProps> = ({
  id,
}: EmployeeNameProps) => {
  const { appState } = useAppContext()
  return <>{resolveEmployeeName(appState, id)}</>
}
