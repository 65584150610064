import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"
import { v4 } from "uuid"

dayjs.extend(utc)

export const id = (): string => v4()

export const deepCopy = (obj: any): any => JSON.parse(JSON.stringify(obj))

export const currentLocalTime = (): Dayjs => dayjs()
export const formatLocalTimestamp = (timestamp: number): string =>
  dayjs(timestamp).format("DD.MM.YYYY HH:mm")
