import { Amplify } from "aws-amplify"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import { getConfig } from "./config"
import UserSessionContextProvider from "./context/UserSessionContext"
import "./index.css"

Amplify.configure({ ...getConfig(window.location.hostname) })

const root = createRoot(document.getElementById("root")!)

root.render(
  // <React.StrictMode> Use strict mode in development time only to detect potential problems
  <UserSessionContextProvider>
    <App />
  </UserSessionContextProvider>,
  // </React.StrictMode>,
)
