import { Typography } from "antd"
import { Vertical } from "../../../common/model/vertical"
import { UploadForm } from "../components/form/UploadForm"
import { VerticalForm } from "../components/form/VerticalForm"
import { VerticalListItem } from "../model/verticals"
import { listingView } from "./ListingView"
import { VerticalDetailsView } from "./VerticalDetailsView"

export const VerticalsView = listingView<Vertical, VerticalListItem>({
  itemsKey: "verticals",
  addItemTitle: "New Vertical",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected verticals?",
  addItemForm: <VerticalForm />,
  editItemForm: (item) => <VerticalForm itemId={item.id} />,
  detailsView: (item) => <VerticalDetailsView itemId={item.id} />,
  uploadItemsForm: (
    <UploadForm
      itemsKey="verticals"
      examples={[
        {
          description: (
            <Typography.Text>
              Import verticals without the <code>id</code> column. Should be
              used when creating new verticals.
            </Typography.Text>
          ),
          rows: ["name", "Entertainment", "Finance"],
        },
        {
          description: (
            <Typography.Text>
              Import verticals with the <code>id</code> column. Should be used
              when modifying existing verticals. Note that the <code>id</code>{" "}
              column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, id",
            "Entertainment, 14a3e998-4ba5-4de4-94ad-ee5f8348f199",
            "Finance, 94eff251-de39-4bad-8f2c-05a5a189a988",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "name",
              required: true,
              description: (
                <Typography.Text>
                  Vertical name. Used to find an existing vertical to update if
                  the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Vertical id. Used to find an existing vertical to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update name of an
                      existing vertical.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  actions: ["remove"],
  columns: [
    {
      property: "name",
      title: "Name",
    },
  ],
  toListItem: (item, appState) => ({ ...item, text: item.name.toLowerCase() }),
})
