import { Typography } from "antd"
import { User } from "../../../common/model/users"
import { UploadForm } from "../components/form/UploadForm"
import { UserForm } from "../components/form/UserForm"
import { UserListItem } from "../model/users"
import { listingView } from "./ListingView"
import { UserDetailsView } from "./UserDetailsView"

export const UsersView = listingView<User, UserListItem>({
  itemsKey: "users",
  addItemTitle: "New User",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected users?",
  addItemForm: <UserForm />,
  editItemForm: (item) => <UserForm itemId={item.id} />,
  detailsView: (item) => <UserDetailsView itemId={item.id} />,
  actions: ["remove"],
  uploadItemsForm: (
    <UploadForm
      itemsKey="users"
      examples={[
        {
          description: (
            <Typography.Text>
              Import users without the <code>id</code> column. Should be used
              when creating new users.
            </Typography.Text>
          ),
          rows: [
            "email, firstName, lastName, role",
            "john.doe@company.com, John, Doe, admin",
            "albert.einstein@company.com, Albert, Einstein, sales",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import users with the <code>id</code> column. Should be used when
              modifying existing users. Note that the <code>id</code> column can
              be empty.
            </Typography.Text>
          ),
          rows: [
            "email, firstName, lastName, role, id",
            "john.doe@company.com, John, Doe, admin, caa65962-1950-4e9d-b1c4-ffc52fae0643",
            "albert.einstein@company.com, Albert, Einstein, sales, b27e5dec-6ddb-4b92-85e7-8549cce9c4a0",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "email",
              required: true,
              description: (
                <Typography.Text>
                  User email address. Used to find an existing user to update if
                  the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "firstName",
              required: true,
              description: "First name",
            },
            {
              name: "lastName",
              required: true,
              description: "Last name",
            },
            {
              name: "role",
              required: true,
              description: "User role. Allowed values: admin, sales, employee",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  User id. Used to find an existing user to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update email
                      address of an existing user.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  columns: [
    {
      property: "email",
      title: "Email",
      width: "400px",
    },
    {
      property: "firstName",
      title: "First Name",
      width: "300px",
    },
    {
      property: "lastName",
      title: "Last Name",
      width: "300px",
    },
    {
      property: "role",
      title: "Role",
    },
  ],
  toListItem: (item, appState) => ({
    ...item,
    text: [item.email, item.lastName, item.firstName, item.role]
      .join(" ")
      .toLowerCase(),
  }),
})
