import {
  Client,
  CreateClientRequest,
  UpdateClientRequest,
} from "../../../common/model/clients"
import {
  Contact,
  CreateContactRequest,
  UpdateContactRequest,
} from "../../../common/model/contacts"
import {
  CreateEmployeeRequest,
  Employee,
  UpdateEmployeeRequest,
} from "../../../common/model/employees"
import {
  CreateKeywordCategoryRequest,
  KeywordCategory,
  UpdateKeywordCategoryRequest,
} from "../../../common/model/keyword-categories"
import {
  CreateKeywordRequest,
  Keyword,
  UpdateKeywordRequest,
} from "../../../common/model/keywords"
import {
  CreateProjectRequest,
  Project,
  UpdateProjectRequest,
} from "../../../common/model/projects"
import {
  CreateUserRequest,
  UpdateUserRequest,
  User,
} from "../../../common/model/users"
import {
  CreateVendorRequest,
  UpdateVendorRequest,
  Vendor,
} from "../../../common/model/vendor"
import {
  CreateVerticalRequest,
  UpdateVerticalRequest,
  Vertical,
} from "../../../common/model/vertical"
import { HttpClientV2, httpClientV2Using } from "./http-client"

export type Api = {
  projects: HttpClientV2<Project, CreateProjectRequest, UpdateProjectRequest>
  clients: HttpClientV2<Client, CreateClientRequest, UpdateClientRequest>
  employees: HttpClientV2<
    Employee,
    CreateEmployeeRequest,
    UpdateEmployeeRequest
  >
  keywords: HttpClientV2<Keyword, CreateKeywordRequest, UpdateKeywordRequest>
  "keyword-categories": HttpClientV2<
    KeywordCategory,
    CreateKeywordCategoryRequest,
    UpdateKeywordCategoryRequest
  >
  verticals: HttpClientV2<
    Vertical,
    CreateVerticalRequest,
    UpdateVerticalRequest
  >
  users: HttpClientV2<User, CreateUserRequest, UpdateUserRequest>
  contacts: HttpClientV2<Contact, CreateContactRequest, UpdateContactRequest>
  vendors: HttpClientV2<Vendor, CreateVendorRequest, UpdateVendorRequest>
}

export const createApiUsing = (authTokenProvider: () => Promise<string>) => {
  const projects = httpClientV2Using<
    Project,
    CreateProjectRequest,
    UpdateProjectRequest
  >({
    basePath: "/api/projects",
    authTokenProvider,
  })

  const clients = httpClientV2Using<
    Client,
    CreateClientRequest,
    UpdateClientRequest
  >({
    basePath: "/api/clients",
    authTokenProvider,
  })

  const employees = httpClientV2Using<
    Employee,
    CreateEmployeeRequest,
    UpdateEmployeeRequest
  >({
    basePath: "/api/employees",
    authTokenProvider,
  })

  const keywords = httpClientV2Using<
    Keyword,
    CreateKeywordRequest,
    UpdateKeywordRequest
  >({
    basePath: "/api/keywords",
    authTokenProvider,
  })

  const keywordCategories = httpClientV2Using<
    KeywordCategory,
    CreateKeywordCategoryRequest,
    UpdateKeywordCategoryRequest
  >({
    basePath: "/api/keyword-categories",
    authTokenProvider,
  })

  const verticals = httpClientV2Using<
    Vertical,
    CreateVerticalRequest,
    UpdateVerticalRequest
  >({
    basePath: "/api/verticals",
    authTokenProvider,
  })

  const vendors = httpClientV2Using<
    Vendor,
    CreateVendorRequest,
    UpdateVendorRequest
  >({
    basePath: "/api/vendors",
    authTokenProvider,
  })

  const users = httpClientV2Using<User, CreateUserRequest, UpdateUserRequest>({
    basePath: "/api/users",
    authTokenProvider,
  })

  const contacts = httpClientV2Using<
    Contact,
    CreateContactRequest,
    UpdateContactRequest
  >({
    basePath: "/api/contacts",
    authTokenProvider,
  })

  return {
    projects,
    "keyword-categories": keywordCategories,
    employees,
    clients,
    keywords,
    verticals,
    users,
    vendors,
    contacts,
  }
}
