export type ResourceName =
  | "clients"
  | "keywords"
  | "keyword-categories"
  | "employees"
  | "projects"
  | "contacts"
  | "verticals"
  | "vendors"
  | "users"

export const RESOURCE_NAMES: ReadonlyArray<ResourceName> = [
  "clients",
  "keywords",
  "keyword-categories",
  "employees",
  "projects",
  "contacts",
  "verticals",
  "vendors",
  "users",
] as const
