import { Space, Typography } from "antd"
import { Vendor } from "../../../common/model/vendor"
import { VendorForm } from "../components/form/VendorForm"
import { detailsView } from "./DetailsView"

export const VendorDetailsView = detailsView<Vendor>({
  itemsKey: "vendors",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <VendorForm itemId={item.id} onComplete={onComplete} onCancel={onCancel} />
  ),
  view: (item, appState) => {
    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
      </Space>
    )
  },
})
