import { Typography } from "antd"
import { KeywordCategory } from "../../../common/model/keyword-categories"
import { KeywordCategoryForm } from "../components/form/KeywordCategoryForm"
import { UploadForm } from "../components/form/UploadForm"
import { KeywordCategoryListItem } from "../model/keyword-categories"
import { KeywordCategoryDetailsView } from "./KeywordCategoryDetailsView"
import { listingView } from "./ListingView"

export const KeywordCategoriesView = listingView<
  KeywordCategory,
  KeywordCategoryListItem
>({
  itemsKey: "keyword-categories",
  addItemTitle: "New Keyword Category",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected keyword categories?",
  addItemForm: <KeywordCategoryForm />,
  editItemForm: (item) => <KeywordCategoryForm itemId={item.id} />,
  detailsView: (item) => <KeywordCategoryDetailsView itemId={item.id} />,
  actions: ["remove"],
  uploadItemsForm: (
    <UploadForm
      itemsKey="keyword-categories"
      examples={[
        {
          description: (
            <Typography.Text>
              Import keyword categories without the <code>id</code> column.
              Should be used when creating new keyword categories.
            </Typography.Text>
          ),
          rows: [
            "name, description",
            "Programming Languages, Programming languages like C++ and Python",
            "Cloud Services, Cloud services like AWS and Azure",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import keyword categories with the <code>id</code> column. Should
              be used when modifying existing keyword categories. Note that the{" "}
              <code>id</code> column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, description, id",
            "Programming Languages, Programming languages like C++ and Python, 9e4e52f8-3471-40ad-98aa-388a23d13807",
            "Cloud Services, Cloud services like AWS and Azure, 94eff251-de39-4bad-8f2c-05a5a189a988",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "name",
              required: true,
              description: (
                <Typography.Text>
                  Keyword category name. Used to find an existing keyword
                  category to update if the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "description",
              description: "Keyword category description",
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Keyword category id. Used to find an existing keyword category
                  to update.
                  <ul>
                    <li>
                      You must specify the id if you want to update name of an
                      existing keyword category.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  columns: [
    {
      property: "name",
      title: "Name",
      width: "400px",
    },
    {
      property: "description",
      title: "Description",
    },
    {
      property: "keywordCount",
      title: "Keywords",
    },
  ],
  toListItem: (item, appState) => {
    const keywordCount = (appState.keywords.data ?? []).filter(
      (k) => k.categoryId === item.id,
    ).length
    return {
      ...item,
      keywordCount,
      text: [item.name].join(" "),
    }
  },
})
